import {useEffect, useMemo, useState} from 'react'
import {IGarminDevice, VisualizerGraphDataType} from '../../../shared/db'
import {
  createDispatchActions,
  selectMethod,
  selectParticipantTimeSeriesData,
  selectProjectSettings,
} from '../../../store'
import {GraphDataTypeMap} from '../../../components/charts/utils/utils'
import {_, useCurrentProjectState} from '../../../lib'
import {TaskStateType, TaskType} from '../../../model'
import {TimeSeriesDataLoaderProps, filterVisualizerSidebarSetting, convertToYYMMIndex} from './utils'
import {GarminDeviceLogDataType} from '../../../shared/mongo'

export const GarminDirectDataLoader = (props: TimeSeriesDataLoaderProps) => {
  const {participantId, loadDataYYMMDDIndex} = props
  const {projectId} = useCurrentProjectState()
  const projectSettings = projectId ? selectProjectSettings()[projectId] : undefined

  const method = selectMethod()
  const garminDeviceTask = useMemo<TaskStateType | undefined>(() => {
    return _.find(method?.taskList, {type: TaskType.GarminDevice}) as TaskStateType | undefined
  }, [method])

  const garminDeviceTaskDetail = useMemo<IGarminDevice | null>(() => {
    return garminDeviceTask?.garminDevice
  }, [garminDeviceTask])

  const particpantTimeSeriesData = selectParticipantTimeSeriesData()
  const currentParticipantTimeSeriesData = useMemo(() => {
    return particpantTimeSeriesData[participantId]
  }, [particpantTimeSeriesData, participantId])
  const [digestCoverdRange, setDigestCoverdRange] = useState<number[]>([])

  const {doREQUEST_PROJECT_GARMIN_DIRECT_DATA_DIGEST, doREQUEST_VISUALIZER_GARMIN_DIRECT_DATA_FETCH}: any =
    createDispatchActions()

  const defaultVisualizerSidebarSetting = useMemo(() => {
    const result = []
    if (garminDeviceTaskDetail) {
      if (garminDeviceTaskDetail?.bbiEnable) {
        result.push(VisualizerGraphDataType.GarminDirectBBI)
      }
      if (garminDeviceTaskDetail?.stepsEnable) {
        result.push(VisualizerGraphDataType.GarminDirectSteps)
      }
      if (garminDeviceTaskDetail?.heartRateEnable) {
        result.push(VisualizerGraphDataType.GarminDirectHeartRate)
      }
      if (garminDeviceTaskDetail?.pulseOxEnable) {
        result.push(VisualizerGraphDataType.GarminDirectSpO2)
      }
      if (garminDeviceTaskDetail?.stressEnable) {
        result.push(VisualizerGraphDataType.GarminDirectStress)
      }
      if (garminDeviceTaskDetail?.actigraphyEnable) {
        result.push(VisualizerGraphDataType.GarminDirectActigraphyRaw)
      }
    }
    return result
  }, [garminDeviceTaskDetail])

  const selectedGarminDirectedDataList = useMemo<VisualizerGraphDataType[]>(() => {
    if (projectSettings?.visualizerSidebarSetting.TimeSeries) {
      const scope = [
        VisualizerGraphDataType.GarminDirectBBI,
        VisualizerGraphDataType.GarminDirectActigraphyRaw,
        VisualizerGraphDataType.GarminDirectHeartRate,
        VisualizerGraphDataType.GarminDirectSpO2,
        VisualizerGraphDataType.GarminDirectStress,
        VisualizerGraphDataType.GarminDirectSteps,
        VisualizerGraphDataType.AnalysisSleepScore,
      ]
      const garminDirectGraphDataList = filterVisualizerSidebarSetting(
        projectSettings?.visualizerSidebarSetting.TimeSeries,
        scope,
      )

      return garminDirectGraphDataList.length > 0
        ? garminDirectGraphDataList
        : [VisualizerGraphDataType.GarminDirectSteps]
    }
    return defaultVisualizerSidebarSetting
  }, [defaultVisualizerSidebarSetting, projectSettings?.visualizerSidebarSetting.TimeSeries])

  const fetchGarminDirectDataDigest = (yymmIndex: number) => {
    doREQUEST_PROJECT_GARMIN_DIRECT_DATA_DIGEST({
      payload: {
        projectId,
        yymmIndexList: [yymmIndex],
      },
    })
    setDigestCoverdRange(digestCoverdRange.concat([yymmIndex]))
  }

  useEffect(() => {
    // attempt to load Garmin Driect data
    if (garminDeviceTask?.garminDevice && selectedGarminDirectedDataList.length) {
      const garminDeviceTaskConfig = garminDeviceTask.garminDevice
      const yymmIndexList: number[] = []
      for (const yymmddIndex of loadDataYYMMDDIndex) {
        const yymmIndex = convertToYYMMIndex(yymmddIndex)
        if (currentParticipantTimeSeriesData?.digestCoveredIndexes?.includes(yymmIndex)) {
          optimisedFetchGarminDirectData(yymmddIndex, garminDeviceTaskConfig)
        } else {
          fetchGarminDirectData(yymmddIndex, garminDeviceTaskConfig)
          if (!yymmIndexList.includes(yymmIndex)) {
            yymmIndexList.push(yymmIndex)
          }
        }
      }
      for (const yymmIndex of yymmIndexList) {
        if (!digestCoverdRange.includes(yymmIndex)) {
          fetchGarminDirectDataDigest(yymmIndex)
        }
      }
    }
  }, [participantId, loadDataYYMMDDIndex, selectedGarminDirectedDataList, garminDeviceTask])

  const fetchGarminDirectData = (yymmddIndex: number, garminDeviceTaskConfig: IGarminDevice) => {
    const timeSeriesData = currentParticipantTimeSeriesData?.data

    const garminDeviceLogDataTypeList = selectedGarminDirectedDataList
      .filter((graphType) => !timeSeriesData || timeSeriesData[graphType]?.[yymmddIndex] === undefined)
      .map((graphType) => GraphDataTypeMap[graphType])

    if (garminDeviceLogDataTypeList.length > 0) {
      doREQUEST_VISUALIZER_GARMIN_DIRECT_DATA_FETCH({
        payload: {
          participantId: participantId,
          yymmddIndex: yymmddIndex,
          garminDeviceTaskConfig,
          dataTypeList: garminDeviceLogDataTypeList,
        },
      })
    }
  }

  const optimisedFetchGarminDirectData = (yymmddIndex: number, garminDeviceTaskConfig: IGarminDevice) => {
    const garminDataDigest = currentParticipantTimeSeriesData?.digest
    if (garminDataDigest) {
      const dataTypeList = []
      // check if the selected date has data first
      for (const [dataType, dateIndexList] of Object.entries(garminDataDigest)) {
        if (dateIndexList.includes(yymmddIndex)) {
          const dataTypeEnum = Object.values(GarminDeviceLogDataType).find((value) => value === dataType)
          const garminDeviceLogDataTypeList = selectedGarminDirectedDataList.map(
            (graphType) => GraphDataTypeMap[graphType],
          )
          if (dataTypeEnum && garminDeviceLogDataTypeList.includes(dataTypeEnum)) {
            dataTypeList.push(dataTypeEnum)
          }
        }
      }
      if (dataTypeList.length > 0) {
        fetchGarminDirectData(yymmddIndex, garminDeviceTaskConfig)
      }
    }
  }
}
