import {assertPartialSchema, createAction, useSelector, v} from '../../../lib'
import {Variable} from '../../../shared/analysis'
export enum AnalyticVariableActionType {
  ANALYTIC_VARIABLE_LIST_SET = 'ANALYTIC_VARIABLE_LIST_SET',
}

export const doANALYTIC_VARIABLE_LIST_SET = createAction(AnalyticVariableActionType.ANALYTIC_VARIABLE_LIST_SET)

export interface AnalyticVariableState {
  [projectId: string]: Variable[]
}

export const analyticVariableInitialState: AnalyticVariableState = {}

export const selectAnalyticVariable = (projectId: string) =>
  useSelector((state: {analyticVariable: AnalyticVariableState}) => state.analyticVariable[projectId] || [])

export const analyticVariableActionCreators = {
  doANALYTIC_VARIABLE_LIST_SET,
}

type Action = {
  type: AnalyticVariableActionType.ANALYTIC_VARIABLE_LIST_SET
  payload: {
    projectId: string
    analyticVariableList: Variable[]
  }
}

export const analyticVariableReducer = (
  state: AnalyticVariableState = analyticVariableInitialState,
  {type, payload}: Action,
): AnalyticVariableState => {
  switch (type) {
    case AnalyticVariableActionType.ANALYTIC_VARIABLE_LIST_SET: {
      assertPartialSchema({
        payload,
        schema: v.object({
          projectId: v.string().uuid().exist(),
          analyticVariableList: v.array().items(
            v.object({
              dataType: v.string().exist(),
              variableType: v.string().exist(),
              identifier: v.string().optional(),
              taskId: v.string().optional(),
              taskName: v.string().optional(),
              threshold: v.number().optional(),
            }),
          ),
        }),
      })
      const {projectId, analyticVariableList} = payload
      return {
        ...state,
        [projectId]: analyticVariableList,
      }
    }
    default:
      return {...state}
  }
}
