import {groupBy} from 'lodash'
import {
  CorrelationResult,
  CorrelationResultAnalysisType,
  CorrelationResultCorrelationData,
  CorrelationResultData,
  CorrelationResultDistributionData,
  CorrelationResultPairwiseTukeyHSDData,
  CorrelationResultTTestData,
  CorrelationResultTwoWayAnovaData,
  CorrelationResultTwoWayAnovaDataItem,
  Variable,
} from '../../../shared/analysis'
import {CorrelationCoordinate} from './correlation_coordinate'
import ReactGridLayout, {WidthProvider} from 'react-grid-layout'
import {selectBatchData, selectTheme} from '../../../store'
import _ from 'lodash'
import {getVariableDisplayName} from '../../../lib/chart_data'
import {Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react'
import {v4} from 'uuid'
import {RIF, useCurrentProjectState} from '../../../engine'
import {DateObject} from 'react-multi-date-picker'
import {VariableTag} from '../../atoms'
import {IParticipant} from '../../../shared/db'
const ReactGridLayoutWidthProvider = WidthProvider(ReactGridLayout)

type DataWithCoord<T extends CorrelationResultData> = {
  data: T
  coord: CorrelationCoordinate
}

enum DataFrameTableItemType {
  Blank = 'blank',
  Header = 'header',
  Value = 'value',
}
interface DataFrameTableItem {
  dataGrid: {
    x: number
    y: number
    w: number
    h: number
    static: boolean
  }
  value: string
  type: DataFrameTableItemType
  coord?: CorrelationCoordinate
  identifier?: string
}

interface CorrelationDataFrameCorrelationCoord {
  individual: string | null
  group: string | null
  duration: string | null
}

const CorrelationDataFrameCorrelation = (props: {
  data: DataWithCoord<CorrelationResultCorrelationData>[]
  selected: CorrelationCoordinate | undefined
  onClickCell: (coord: CorrelationCoordinate) => void
  displayingVariableList: Variable[]
  duration: [DateObject, DateObject]
}) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {data, selected, onClickCell, displayingVariableList, duration} = props
  const {project} = useCurrentProjectState()
  const batchId: string | undefined = project?.batchList?.[0]?.id
  const participantList: IParticipant[] = selectBatchData()?.[batchId ?? '']?.participantList || []
  const [participantInsigniaTable, setParticipantInsigniaTable] = useState<{[key: string]: string}>({})

  const defaultCoord = {individual: null, group: null, duration: null}
  const tableDefault: DataFrameTableItem = {
    dataGrid: {x: 0, y: 0, w: 1, h: 1, static: true},
    value: '',
    type: DataFrameTableItemType.Blank,
  }
  const [coord, setCoord] = useState<CorrelationDataFrameCorrelationCoord>(defaultCoord)
  const [table, setTable] = useState<DataFrameTableItem[]>([tableDefault])
  const [header, setHeader] = useState<DataFrameTableItem[]>([])
  useMemo(() => {
    if (!displayingVariableList.length) return setHeader([])
    const result = displayingVariableList.flatMap((variable, i) => [
      {
        dataGrid: {x: i + 1, y: 0, w: 1, h: 1, static: true},
        value: getVariableDisplayName(variable),
        type: DataFrameTableItemType.Header,
        identifier: variable.identifier,
      },
      {
        dataGrid: {x: 0, y: i + 1, w: 1, h: 1, static: true},
        value: getVariableDisplayName(variable),
        type: DataFrameTableItemType.Header,
        identifier: variable.identifier,
      },
    ])
    setHeader(result)
  }, [displayingVariableList])

  const getIndex = (str: string): number => {
    if (!str.length) return 0
    return str.charCodeAt(str.length - 1) - 'A'.charCodeAt(0) + 1
  }
  const [tabList, setTabList] = useState<CorrelationDataFrameCorrelationCoord[]>([])

  useMemo(() => {
    if (!data.length) return
    const durationList: Set<string> = new Set()
    const individualList: Set<string> = new Set()
    const groupList: Set<string> = new Set()
    data.forEach(({data}) => {
      const {duration, individual, group} = data.coord
      if (duration) durationList.add(duration)
      if (individual) {
        individualList.add(individual)
        const findResult = _.find(participantList, ['id', individual])
        setParticipantInsigniaTable((prev) => {
          return {
            ...prev,
            [individual]: findResult?.insignia ?? 'Unknown',
          }
        })
      }
      if (group) groupList.add(group)
    })
    const defaultCoord = {individual: null, group: null, duration: null}
    const tempTabList: CorrelationDataFrameCorrelationCoord[] = []
    if (durationList.size > 1) {
      // multiple duration
      if (individualList.size > 1) {
        // multiple individual
        for (const individual of individualList) {
          for (const duration of durationList) {
            tempTabList.push({...defaultCoord, individual, duration})
          }
        }
      } else if (groupList.size > 1) {
        // multiple group
        for (const group of groupList) {
          for (const duration of durationList) {
            tempTabList.push({...defaultCoord, group, duration})
          }
        }
      } else {
        // single individual or group
        for (const duration of durationList) {
          tempTabList.push({...defaultCoord, duration})
        }
      }
    } else {
      // single duration
      if (individualList.size > 1) {
        // multiple individual
        for (const individual of individualList) {
          tempTabList.push({...defaultCoord, individual})
        }
      } else if (groupList.size > 1) {
        // multiple group
        for (const group of groupList) {
          tempTabList.push({...defaultCoord, group})
        }
      }
    }
    if (!tempTabList.length) tempTabList.push(defaultCoord)
    setTabList(tempTabList)
    setCoord(tempTabList[0])
  }, [data])

  useMemo(() => {
    if (!data.length) return setTable([])
    const result = data
      .filter((d) => {
        return _.isEqual(d.data.coord, coord) || _.isEqual(coord, defaultCoord)
      })
      .map(({data, coord}) => ({
        dataGrid: {x: getIndex(coord.column), y: getIndex(coord.row), w: 1, h: 1, static: true},
        value: `${data.r_value?.toFixed(2)}`,
        type: DataFrameTableItemType.Value,
        coord,
      }))
    setTable([tableDefault, ...header, ...result])
  }, [coord, data])

  return (
    <>
      {RIF(
        tabList.length > 1,
        <CorrelationDataFrameCorrelationTabList
          {...{
            tabList,
            participantInsigniaTable,
            coord,
            setCoord,
            duration,
          }}
        />,
      )}
      <ReactGridLayoutWidthProvider
        css={{
          border: `1.5px solid ${color.border._80}`,
          boxSizing: 'border-box',
          borderRadius: tabList.length > 1 ? '0 0 8px 8px' : '8px',
          overflow: 'hidden',
          backgroundColor: color.white,
        }}
        cols={displayingVariableList.length + 1}
        rowHeight={30}
        margin={[0, 0]}
      >
        {table.map(({dataGrid, value, type, coord, identifier}) => {
          const cellSelected = coord && selected?.row === coord.row && selected?.column === coord.column
          return (
            <div
              css={{
                border: `.5px solid ${cellSelected ? color.border._400 : color.border._80}`,
                boxSizing: 'border-box',
                overflow: 'hidden',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                backgroundColor:
                  type === DataFrameTableItemType.Header
                    ? color.surface.grey.light
                    : cellSelected
                    ? color.surface.grey.dark
                    : color.white,
                justifyContent: type === DataFrameTableItemType.Value ? 'center' : 'flex-start',
                padding: '8px',
                color: type === DataFrameTableItemType.Value ? color.textIcon.secondary : color.textIcon.light,
                fontSize: fontSize.h7,
              }}
              onClick={() => {
                if (coord) onClickCell(coord)
              }}
              key={`${dataGrid.x}-${dataGrid.y}`}
              data-grid={dataGrid}
            >
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                }}
              >
                {RIF(type === DataFrameTableItemType.Header, <VariableTag identifier={identifier} />)}
                <p
                  css={{
                    fontSize: fontSize.h7,
                    color: color.textIcon.secondary,
                  }}
                >
                  {value === 'undefined' ? 'No Data' : value}
                </p>
              </div>
            </div>
          )
        })}
      </ReactGridLayoutWidthProvider>
    </>
  )
}

const CorrelationDataFrameCorrelationTabList = (props: {
  tabList: CorrelationDataFrameCorrelationCoord[]
  participantInsigniaTable: {[key: string]: string}
  coord: CorrelationDataFrameCorrelationCoord
  setCoord: Dispatch<SetStateAction<CorrelationDataFrameCorrelationCoord>>
  duration: [DateObject, DateObject]
}) => {
  const {setCoord, tabList, coord, participantInsigniaTable, duration} = props
  const {color, fontSize, fontWeight} = selectTheme()

  const getTabText = (tab: CorrelationDataFrameCorrelationCoord, index: number): string => {
    if (tab.duration) {
      if (tab.individual) {
        return `${participantInsigniaTable[tab.individual]} (${tab.duration})`
      }
      if (tab.group) {
        return `${tab.group} (${tab.duration})`
      }
      return tab.duration
    } else {
      if (tab.individual) {
        return participantInsigniaTable[tab.individual]
      } else if (tab.group) {
        return tab.group
      }
    }
    return `Sample ${index + 1}`
  }
  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'flex-end',
      }}
    >
      {tabList.map((tab, index) => (
        <button
          key={v4()}
          onClick={() => {
            setCoord(tab)
          }}
          css={{
            border: `2px solid ${color.border._80}`,
            borderBottom: 'none',
            borderRadius: '8px 8px 0px 0px',
            padding: _.isEqual(tab, coord) ? '8px 24px' : '4px 24px',
            backgroundColor: _.isEqual(tab, coord) ? color.white : color.surface.grey.dark,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: fontSize.h7,
            fontWeight: fontWeight.bold,
            color: color.textIcon.light,
            cursor: 'pointer',
          }}
        >
          {getTabText(tab, index)}
        </button>
      ))}
    </div>
  )
}

const CorrelationDataFrameDistribution = (props: {
  data: DataWithCoord<CorrelationResultDistributionData>[]
  displayName: (identifier: string) => string
  selected: CorrelationCoordinate | undefined
  onClickCell: (coord: CorrelationCoordinate) => void
}) => {
  const {data, selected, onClickCell, displayName} = props
  const {color, fontSize} = selectTheme()
  const table: DataFrameTableItem[] = [
    {dataGrid: {x: 0, y: 0, w: 4, h: 1, static: true}, type: DataFrameTableItemType.Blank, value: ''},
    {dataGrid: {x: 4, y: 0, w: 1, h: 1, static: true}, type: DataFrameTableItemType.Header, value: 'N'},
    {dataGrid: {x: 5, y: 0, w: 1, h: 1, static: true}, type: DataFrameTableItemType.Header, value: 'Mean'},
    {dataGrid: {x: 6, y: 0, w: 1, h: 1, static: true}, type: DataFrameTableItemType.Header, value: 'SD'},
    {dataGrid: {x: 7, y: 0, w: 1, h: 1, static: true}, type: DataFrameTableItemType.Header, value: 'SE'},
    // {dataGrid: {x: 0, y: 1, w: 8, h: 1, static: true}, type: DataFrameTableItemType.Header, value: 'distribution'},
  ]
  data.forEach(({data, coord}, index) => {
    table.push(
      {
        dataGrid: {x: 0, y: index + 1, w: 4, h: 1, static: true},
        type: DataFrameTableItemType.Header,
        value: displayName(coord.column),
        coord,
        identifier: coord.column,
      },
      {
        dataGrid: {x: 4, y: index + 1, w: 1, h: 1, static: true},
        type: DataFrameTableItemType.Value,
        value: `${data.length}`,
        coord,
      },
      {
        dataGrid: {x: 5, y: index + 1, w: 1, h: 1, static: true},
        type: DataFrameTableItemType.Value,
        value: `${data.mean?.toFixed(2)}`,
        coord,
      },
      {
        dataGrid: {x: 6, y: index + 1, w: 1, h: 1, static: true},
        type: DataFrameTableItemType.Value,
        value: `${data.std?.toFixed(2)}`,
        coord,
      },
      {
        dataGrid: {x: 7, y: index + 1, w: 1, h: 1, static: true},
        type: DataFrameTableItemType.Value,
        value: `${data.sem?.toFixed(2)}`,
        coord,
      },
    )
  })
  return (
    <ReactGridLayoutWidthProvider
      css={{
        border: `1.5px solid ${color.border._80}`,
        borderRadius: '5px',
        overflow: 'hidden',
        marginTop: '32px',
        backgroundColor: color.white,
      }}
      cols={8}
      rowHeight={30}
      margin={[0, 0]}
    >
      {table.map(({dataGrid, value, coord, type, identifier}) => {
        const cellSelected = coord && selected?.row === coord.row && selected?.column === coord.column
        return (
          <div
            css={{
              border: `.5px solid ${cellSelected ? color.border._400 : color.border._80}`,
              backgroundColor:
                type === DataFrameTableItemType.Header
                  ? color.surface.grey.light
                  : cellSelected
                  ? color.surface.grey.dark
                  : color.white,
              boxSizing: 'border-box',
              display: 'flex',
              alignItems: 'center',
              padding: '8px',
            }}
            key={`${dataGrid.x}-${dataGrid.y}`}
            data-grid={dataGrid}
            onClick={() => {
              if (coord) onClickCell(coord)
            }}
          >
            {RIF(identifier, <VariableTag identifier={identifier} />)}
            <p
              css={{
                fontSize: fontSize.h7,
                color: color.textIcon.secondary,
              }}
            >
              {value === 'undefined' ? 'No Data' : value}
            </p>
          </div>
        )
      })}
    </ReactGridLayoutWidthProvider>
  )
}

// TODO: implement
const CorrelationDataFramePairedSampleTTest = (props: {
  data: DataWithCoord<CorrelationResultTTestData>[]
  displayName: (identifier: string) => string
  selected: CorrelationCoordinate | undefined
  onClickCell: (coord: CorrelationCoordinate) => void
}) => {
  return <div></div>
}

const CorrelationDataFrameTwoSampleTTest = (props: {
  data: DataWithCoord<CorrelationResultTTestData>[]
  displayName: (identifier: string) => string
  selected: CorrelationCoordinate | undefined
  onClickCell: (coord: CorrelationCoordinate) => void
}) => {
  const {data, selected, onClickCell, displayName} = props
  const {color, fontSize} = selectTheme()
  const table: DataFrameTableItem[] = [
    {dataGrid: {x: 0, y: 0, w: 1, h: 1, static: true}, type: DataFrameTableItemType.Blank, value: ''},
    {dataGrid: {x: 1, y: 0, w: 1, h: 1, static: true}, type: DataFrameTableItemType.Header, value: 'N1'},
    {dataGrid: {x: 2, y: 0, w: 1, h: 1, static: true}, type: DataFrameTableItemType.Header, value: 'Mean1'},
    {dataGrid: {x: 3, y: 0, w: 1, h: 1, static: true}, type: DataFrameTableItemType.Header, value: 'SD1'},
    {dataGrid: {x: 4, y: 0, w: 1, h: 1, static: true}, type: DataFrameTableItemType.Header, value: 'SE1'},
    {dataGrid: {x: 5, y: 0, w: 1, h: 1, static: true}, type: DataFrameTableItemType.Header, value: 'N2'},
    {dataGrid: {x: 6, y: 0, w: 1, h: 1, static: true}, type: DataFrameTableItemType.Header, value: 'Mean2'},
    {dataGrid: {x: 7, y: 0, w: 1, h: 1, static: true}, type: DataFrameTableItemType.Header, value: 'SD2'},
    {dataGrid: {x: 8, y: 0, w: 1, h: 1, static: true}, type: DataFrameTableItemType.Header, value: 'SE2'},
    {dataGrid: {x: 9, y: 0, w: 1, h: 1, static: true}, type: DataFrameTableItemType.Header, value: 't-value'},
    {dataGrid: {x: 10, y: 0, w: 1, h: 1, static: true}, type: DataFrameTableItemType.Header, value: '95% CI'},
    {dataGrid: {x: 11, y: 0, w: 1, h: 1, static: true}, type: DataFrameTableItemType.Header, value: 'p-value'},
    {dataGrid: {x: 12, y: 0, w: 1, h: 1, static: true}, type: DataFrameTableItemType.Header, value: 't-value'},
  ]
  data.forEach(({data, coord}, yIndex) => {
    table.push(
      {
        dataGrid: {x: 0, y: yIndex + 1, w: 1, h: 1, static: true},
        type: DataFrameTableItemType.Value,
        value: displayName(coord.column).match(/\(([^)]+)\)/)?.[1] ?? coord.column,
        coord,
        identifier: coord.column,
      },
      {
        dataGrid: {x: 9, y: yIndex + 1, w: 1, h: 1, static: true},
        type: DataFrameTableItemType.Value,
        value: `${data.t_value?.toFixed(2)}`,
        coord,
      },
      {
        dataGrid: {x: 10, y: yIndex + 1, w: 1, h: 1, static: true},
        type: DataFrameTableItemType.Value,
        value: `(${data.ci_95_low?.toFixed(2)}, ${data.ci_95_high?.toFixed(2)})`,
        coord,
      },
      {
        dataGrid: {x: 11, y: yIndex + 1, w: 1, h: 1, static: true},
        type: DataFrameTableItemType.Value,
        value: `${data.p_value?.toFixed(2)}`,
        coord,
      },
      {
        dataGrid: {x: 12, y: yIndex + 1, w: 1, h: 1, static: true},
        type: DataFrameTableItemType.Value,
        value: `${data.bh_adjusted_p_value?.toFixed(2)}`,
        coord,
      },
    )
    data.distributions.forEach(({length, mean, std, sem}, xIndex) => {
      table.push(
        {
          dataGrid: {x: xIndex * 4 + 1, y: yIndex + 1, w: 1, h: 1, static: true},
          type: DataFrameTableItemType.Value,
          value: `${length}`,
          coord,
        },
        {
          dataGrid: {x: xIndex * 4 + 2, y: yIndex + 1, w: 1, h: 1, static: true},
          type: DataFrameTableItemType.Value,
          value: `${mean?.toFixed(2)}`,
          coord,
        },
        {
          dataGrid: {x: xIndex * 4 + 3, y: yIndex + 1, w: 1, h: 1, static: true},
          type: DataFrameTableItemType.Value,
          value: `${std?.toFixed(2)}`,
          coord,
        },
        {
          dataGrid: {x: xIndex * 4 + 4, y: yIndex + 1, w: 1, h: 1, static: true},
          type: DataFrameTableItemType.Value,
          value: `${sem?.toFixed(2)}`,
          coord,
        },
      )
    })
  })
  return (
    <ReactGridLayoutWidthProvider
      css={{
        border: `1.5px solid ${color.border._80}`,
        borderRadius: '5px',
        overflow: 'hidden',
        marginTop: '32px',
        backgroundColor: color.white,
      }}
      cols={13}
      rowHeight={30}
      margin={[0, 0]}
    >
      {table.map(({dataGrid, value, coord, type, identifier}) => {
        const cellSelected = coord && selected?.row === coord.row && selected?.column === coord.column
        return (
          <div
            css={{
              border: `.5px solid ${cellSelected ? color.border._400 : color.border._80}`,
              backgroundColor:
                type === DataFrameTableItemType.Header
                  ? color.surface.grey.light
                  : cellSelected
                  ? color.surface.grey.dark
                  : color.white,
              boxSizing: 'border-box',
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'nowrap',
              padding: '8px',
              overflowX: 'scroll',
            }}
            key={`${dataGrid.x}-${dataGrid.y}`}
            data-grid={dataGrid}
            onClick={() => {
              if (coord) onClickCell(coord)
            }}
          >
            {RIF(identifier, <VariableTag identifier={identifier} />)}
            <p
              css={{
                fontSize: fontSize.h7,
                color: color.textIcon.secondary,
              }}
            >
              {value === 'undefined' ? 'No Data' : value}
            </p>
          </div>
        )
      })}
    </ReactGridLayoutWidthProvider>
  )
}

// TODO: implement CorrelationResultRepeatedMeasuresCorrelationData data frame
const CorrelationDataFrameRepeatedMeasuresCorrelation = (props: {
  data: DataWithCoord<CorrelationResultCorrelationData>[]
  selected: CorrelationCoordinate | undefined
  onClickCell: (coord: CorrelationCoordinate) => void
}) => {
  return <div></div>
}

// TODO: implement CorrelationResultTwoWayAnovaData data frame
const CorrelationDataFrameTwoWayAnova = (props: {
  data: DataWithCoord<CorrelationResultTwoWayAnovaData>[]
  selected: CorrelationCoordinate | undefined
  onClickCell: (coord: CorrelationCoordinate) => void
}) => {
  return <div></div>
}

// TODO: implement CorrelationResultPairwiseTukeyHSDData data frame
const CorrelationDataFramePairwiseTukeyHSD = (props: {
  data: DataWithCoord<CorrelationResultPairwiseTukeyHSDData>[]
  selected: CorrelationCoordinate | undefined
  onClickCell: (coord: CorrelationCoordinate) => void
}) => {
  return <div></div>
}

export const CorrelationDataFrameBlock = (
  props: CorrelationResult & {
    displayName: (identifier: string) => string
    selected: CorrelationCoordinate | undefined
    onClickCell: (coord: CorrelationCoordinate) => void
    displayingVariableList: Variable[]
    duration: [DateObject, DateObject]
  },
) => {
  const {items} = props
  const data = items.flatMap((row) => row.flatMap((item) => item.data.map((data) => ({data, coord: item.coord}))))
  const dataGroups = groupBy(data, ({data}) => data.analysis_type)
  return (
    <div
      css={{
        marginBottom: '32px',
      }}
    >
      {Object.entries(dataGroups).map(([analysisType, dataGroup]) => {
        switch (analysisType) {
          case CorrelationResultAnalysisType.Correlation:
            return (
              <CorrelationDataFrameCorrelation
                key={analysisType}
                data={dataGroup as DataWithCoord<CorrelationResultCorrelationData>[]}
                selected={props.selected}
                onClickCell={props.onClickCell}
                displayingVariableList={props.displayingVariableList}
                duration={props.duration}
              />
            )
          case CorrelationResultAnalysisType.Distribution:
            return (
              <CorrelationDataFrameDistribution
                key={analysisType}
                data={dataGroup as DataWithCoord<CorrelationResultDistributionData>[]}
                selected={props.selected}
                onClickCell={props.onClickCell}
                displayName={props.displayName}
              />
            )
          case CorrelationResultAnalysisType.PairedSampleTTest:
            return (
              <CorrelationDataFramePairedSampleTTest
                key={analysisType}
                data={dataGroup as DataWithCoord<CorrelationResultTTestData>[]}
                selected={props.selected}
                onClickCell={props.onClickCell}
                displayName={props.displayName}
              />
            )
          case CorrelationResultAnalysisType.TwoSampleTTest:
            return (
              <CorrelationDataFrameTwoSampleTTest
                key={analysisType}
                displayName={props.displayName}
                data={dataGroup as DataWithCoord<CorrelationResultTTestData>[]}
                selected={props.selected}
                onClickCell={props.onClickCell}
              />
            )
          case CorrelationResultAnalysisType.RepeatedMeasuresCorrelation:
            return (
              <CorrelationDataFrameRepeatedMeasuresCorrelation
                key={analysisType}
                data={dataGroup as DataWithCoord<CorrelationResultCorrelationData>[]}
                selected={props.selected}
                onClickCell={props.onClickCell}
              />
            )
          case CorrelationResultAnalysisType.TwoWayAnova:
            return (
              <CorrelationDataFrameTwoWayAnova
                key={analysisType}
                data={dataGroup as DataWithCoord<CorrelationResultTwoWayAnovaData>[]}
                selected={props.selected}
                onClickCell={props.onClickCell}
              />
            )
          case CorrelationResultAnalysisType.PairwiseTukeyHSD:
            return (
              <CorrelationDataFramePairwiseTukeyHSD
                key={analysisType}
                data={dataGroup as DataWithCoord<CorrelationResultPairwiseTukeyHSDData>[]}
                selected={props.selected}
                onClickCell={props.onClickCell}
              />
            )
          default:
            throw new Error(`unhandled analysis type: ${analysisType}`)
        }
      })}
    </div>
  )
}
