import {CSSProperties} from 'react'
import {
  CorrelationResultCoordinate,
  CorrelationResultCorrelationData,
  RequestVariablesId,
  TrendResultGraph,
} from '../../../shared/analysis'
import {CorrelationCoordinate} from './correlation_coordinate'
import {selectTheme} from '../../../store'
import Plot, {PlotParams} from 'react-plotly.js'

const TrendGraphPlot = (props: {graph: string; style: CSSProperties; onClick?: () => void}) => {
  const style = {
    ...props.style,
    color: undefined,
  }

  const params = JSON.parse(props.graph) as PlotParams

  // update listeners
  params.onClick = () => props.onClick?.()

  // update layout
  params.layout = {
    ...params.layout,
    title: undefined,
    margin: {
      t: 0,
      b: 0,
      l: 0,
      r: 0,
      pad: 0,
    },
    paper_bgcolor: style.backgroundColor,
    plot_bgcolor: style.backgroundColor,
    yaxis: {
      visible: false,
    },
    xaxis: {
      visible: false,
    },
    showlegend: false,
    bargap: 0.5,
  }

  return <Plot {...params} style={style} />
}

const defaultTrendGraphStyle: Readonly<CSSProperties> = {
  width: '100%',
  height: '144px',
  backgroundColor: '#F2F3F3',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const TrendGraph = (props: {
  coord: RequestVariablesId
  item: TrendResultGraph
  style?: CSSProperties
  containerWidth?: string
  selected?: CorrelationCoordinate
  onClickGraph: (coord: CorrelationResultCoordinate) => void
}) => {
  const {coord, item, selected, containerWidth} = props
  const {color, fontSize, fontWeight} = selectTheme()
  // const graphSelected = selected?.row === coord.row && selected?.column === coord.column
  const graphSelected = false
  const style = {
    ...defaultTrendGraphStyle,
    ...props.style,
    backgroundColor: graphSelected ? color.surface.grey.dark : defaultTrendGraphStyle.backgroundColor,
  }

  const onClick = () => {
    return
  }

  // const onClick = () => {
  //   props.onClickGraph({
  //     row: coord.row,
  //     column: coord.column,
  //   })
  // }

  const graph = props.item.graph
  if (graph) {
    return (
      <div
        css={{
          border: `1px solid ${graphSelected ? color.border._400 : color.white}`,
          boxSize: 'border-box',
          overFlow: 'hidden',
          display: 'flex',
          width: containerWidth ?? '100%',
        }}
      >
        <TrendGraphPlot {...{onClick, graph, style}} />
      </div>
    )
  }

  return <div style={style} />
}
