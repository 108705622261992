import {useState, Dispatch, SetStateAction, useEffect} from 'react'
import {selectBatchData, selectTheme} from '../../store'
import {_, getVariableDisplayName, RIF, t, useCurrentProjectState} from '../../lib'
import {Scrollbars} from 'react-custom-scrollbars-2'
import {Variable, TrendResponse, CorrelationResultCoordinate, TrendResultOLSData} from '../../shared/analysis'
import {ParticipantItem, ParticipantStateType} from '../../model'
import {DateObject} from 'react-multi-date-picker'
import {v4} from 'uuid'
import {TrendGraph} from '../charts/analytic_graph/trend_graph'
import {VariableTag} from '../atoms'
import {TrendDataFrame} from '../charts/analytic_graph/trend_data_frame_block'
import {set} from 'lodash'

export interface AnalysisTrendGraphBlockProps {
  displayingVariableList: Variable[]
  trendResponse?: TrendResponse
  duration: [DateObject, DateObject]
  trendOlsData: TrendResultOLSData[]
}

function formatDate(yymmddIndex: number): string {
  const numStr = yymmddIndex.toString()

  const month = parseInt(numStr.slice(2, 4)) - 1
  const day = parseInt(numStr.slice(4, 6))

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  return `${monthNames[month]}/${day}`
}

interface TrendResponseMetadataSingleTargetSingleDuration {
  participantId: string
  yymmddIndexStart: number
  yymmddIndexEnd: number
}

const TrendAnalysisGraphSingleTargetSingleDuration = (props: {
  trendResponse?: TrendResponse
  displayingVariableList: Variable[]
  participantList: ParticipantStateType[]
}) => {
  const {color, fontWeight, fontSize} = selectTheme()
  const {trendResponse, displayingVariableList, participantList} = props
  const [participantInsignia, setParticipantInsignia] = useState<string>('')
  const [duration, setDuration] = useState<[string, string]>(['', ''])
  useEffect(() => {
    const metadata = trendResponse?.metadata as TrendResponseMetadataSingleTargetSingleDuration
    if (!metadata) return
    if (!Object.keys(metadata).includes('participantId')) return
    const participant = participantList.find((participant) => participant.id === metadata.participantId)
    const tempParticipantInsignia = participant?.insignia ?? ''
    setParticipantInsignia(tempParticipantInsignia)
    setDuration([formatDate(metadata.yymmddIndexStart), formatDate(metadata.yymmddIndexEnd)])
  }, [trendResponse])
  return (
    <>
      {trendResponse?.result?.graph.map((graph) => {
        const {coord, variable_name} = graph
        return (
          <div
            key={v4()}
            css={{
              width: '100%',
              marginBottom: '8px',
              padding: '16px',
              borderRadius: '5px',
              backgroundColor: color.white,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              css={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '8px',
              }}
            >
              {/* variable name + tag */}
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <VariableTag identifier={variable_name} />
                <p
                  css={{
                    fontSize: fontSize.h7,
                    fontWeight: fontWeight.thick,
                  }}
                >
                  {getVariableDisplayName(
                    _.find(displayingVariableList, ['identifier', variable_name]) ?? displayingVariableList[0],
                  )}
                </p>
              </div>
              {/* participant insignia */}
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  css={{
                    marginRight: '8px',
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    backgroundColor: color.tag.blue.dark,
                  }}
                />
                <p css={{fontSize: fontSize.h7}}>{`${participantInsignia} (${duration[0]} - ${duration[1]})`}</p>
              </div>
            </div>
            <TrendGraph
              {...{
                coord,
                item: graph,
                onClickGraph: (coord: CorrelationResultCoordinate) => {
                  return
                },
              }}
            />
          </div>
        )
      })}
    </>
  )
}

interface TrendResponseMetadataMultiTargetSingleDuration {
  participantIds: string[]
  yymmddIndexStart: number
  yymmddIndexEnd: number
}

const TrendAnalysisGraphMultiTargetSingleDuration = (props: {
  trendResponse?: TrendResponse
  displayingVariableList: Variable[]
  participantList: ParticipantStateType[]
}) => {
  const {color, fontWeight, fontSize} = selectTheme()
  const {trendResponse, displayingVariableList, participantList} = props
  const [participantInsignias, setParticipantInsignias] = useState<string[]>(['', ''])
  const [duration, setDuration] = useState<[string, string]>(['', ''])
  useEffect(() => {
    const metadata = trendResponse?.metadata as TrendResponseMetadataMultiTargetSingleDuration
    if (!metadata) return
    if (!Object.keys(metadata).includes('participantIds')) return
    const tempParticipantInsignias = metadata.participantIds.map((participantId) => {
      const participant = participantList.find((participant) => participant.id === participantId)
      return participant?.insignia ?? ''
    })
    setParticipantInsignias(tempParticipantInsignias)
    setDuration([formatDate(metadata.yymmddIndexStart), formatDate(metadata.yymmddIndexEnd)])
  }, [trendResponse])
  return (
    <>
      {trendResponse?.result?.graph.map((graph) => {
        const {coord, variable_name} = graph
        return (
          <div
            key={variable_name}
            css={{
              width: '100%',
              marginBottom: '8px',
              padding: '16px',
              borderRadius: '5px',
              backgroundColor: color.white,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              css={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '8px',
              }}
            >
              {/* variable name + tag */}
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <VariableTag identifier={variable_name} />
                <p
                  css={{
                    fontSize: fontSize.h7,
                    fontWeight: fontWeight.thick,
                  }}
                >
                  {getVariableDisplayName(
                    _.find(displayingVariableList, ['identifier', variable_name]) ?? displayingVariableList[0],
                  )}
                </p>
              </div>
              {/* participant insignia */}
              {participantInsignias.map((insignia) => (
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div
                    css={{
                      marginRight: '8px',
                      width: '12px',
                      height: '12px',
                      borderRadius: '50%',
                      backgroundColor: color.tag.blue.dark,
                    }}
                  />
                  <p css={{fontSize: fontSize.h7}}>{`${insignia} (${duration[0]} - ${duration[1]})`}</p>
                </div>
              ))}
            </div>
            <TrendGraph
              {...{
                coord,
                item: graph,
                onClickGraph: (coord: CorrelationResultCoordinate) => {
                  return
                },
              }}
            />
          </div>
        )
      })}
    </>
  )
}

interface TrendResponseMetadataSingleTargetMultiDuration {
  participantId: string
  yymmddDurations: {
    [key: string]: {
      yymmddIndexStart: number
      yymmddIndexEnd: number
    }
  }
}

const TrendAnalysisGraphSingleTargetMultiDuration = (props: {
  trendResponse?: TrendResponse
  displayingVariableList: Variable[]
  participantList: ParticipantStateType[]
}) => {
  const {color, fontWeight, fontSize} = selectTheme()
  const {trendResponse, displayingVariableList, participantList} = props
  const [durations, setDurations] = useState<string[]>([])
  useEffect(() => {
    const metadata = trendResponse?.metadata as TrendResponseMetadataSingleTargetMultiDuration
    if (!metadata) return
    if (!Object.keys(metadata).includes('yymmddDurations')) return
    setDurations(Object.keys(metadata.yymmddDurations))
  }, [trendResponse])
  return (
    <>
      {displayingVariableList.map((displayingVariable, index) => {
        const {identifier} = displayingVariable
        const graphList = trendResponse?.result?.graph.filter((graph) => graph.variable_name === identifier) ?? []

        return (
          <div
            key={identifier}
            css={{
              width: '100%',
              marginBottom: '8px',
              padding: '16px',
              borderRadius: '5px',
              backgroundColor: color.white,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                marginBottom: '8px',
              }}
            >
              <VariableTag identifier={identifier} />
              <p
                css={{
                  fontSize: fontSize.h7,
                  fontWeight: fontWeight.thick,
                }}
              >
                {getVariableDisplayName(
                  _.find(displayingVariableList, ['identifier', identifier]) ?? displayingVariableList[0],
                )}
              </p>
            </div>
            <div
              css={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {graphList.map((graph, index) => {
                const {coord} = graph
                return (
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      overflow: 'hidden',
                      width: `calc(100% / ${graphList.length} - 8px)`,
                    }}
                  >
                    <div
                      css={{
                        padding: '4px 8px',
                        backgroundColor: color.surface.dark,
                        fontSize: fontSize.h8,
                        color: color.white,
                        width: 'fit-content',
                        marginBottom: '4px',
                      }}
                    >
                      {durations[index]}
                    </div>
                    <TrendGraph
                      {...{
                        coord,
                        item: graph,
                        // containerWidth: `calc(100% / ${graphList.length} - 8px)`,
                        onClickGraph: (coord: CorrelationResultCoordinate) => {
                          return
                        },
                      }}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </>
  )
}

export const AnalysisTrendGraphBlock = (props: AnalysisTrendGraphBlockProps) => {
  const {color, fontWeight, fontSize} = selectTheme()
  const {trendOlsData, displayingVariableList, trendResponse, duration} = props
  const {project} = useCurrentProjectState()
  const batchId: string | undefined = project?.batchList?.[0]?.id
  const participantList: ParticipantStateType[] = selectBatchData()?.[batchId ?? '']?.participantList || []
  const [participantInsigniaTable, setParticipantInsigniaTable] = useState<{[key: string]: string}>({})
  const defaultCoord = {individual: null, group: null, duration: null}
  const trendResponseMetadata = trendResponse?.metadata ?? {}
  const singleTargetSingleDurationMetadata = ['participantId', 'yymmddIndexStart', 'yymmddIndexEnd']
  const multiTargetSingleDurationMetadata = ['participantIds', 'yymmddIndexStart', 'yymmddIndexEnd']
  const singleTargetMultiDurationMetadata = ['participantId', 'yymmddDurations']
  return (
    <div
      css={{
        flex: 1,
      }}
    >
      <Scrollbars autoHide autoHideTimeout={200}>
        {RIF(
          _.intersection(Object.keys(trendResponseMetadata), singleTargetSingleDurationMetadata).length ===
            singleTargetSingleDurationMetadata.length,
          <TrendAnalysisGraphSingleTargetSingleDuration
            {...{
              trendResponse,
              displayingVariableList,
              participantList,
            }}
          />,
        )}
        {RIF(
          _.intersection(Object.keys(trendResponseMetadata), singleTargetMultiDurationMetadata).length ===
            singleTargetMultiDurationMetadata.length,
          <TrendAnalysisGraphSingleTargetMultiDuration
            {...{
              trendResponse,
              displayingVariableList,
              participantList,
            }}
          />,
        )}
        {RIF(
          _.intersection(Object.keys(trendResponseMetadata), multiTargetSingleDurationMetadata).length ===
            multiTargetSingleDurationMetadata.length,
          <TrendAnalysisGraphMultiTargetSingleDuration
            {...{
              trendResponse,
              displayingVariableList,
              participantList,
            }}
          />,
        )}

        {RIF(
          trendResponse?.result?.data?.length,
          <TrendDataFrame
            {...{
              data: trendOlsData,
              duration,
              displayingVariableList,
              // displayName: getVariableDisplayName(displayingVariableList[0]),
            }}
          />,
        )}
      </Scrollbars>
    </div>
  )
}
