import {snakeToCamel} from '../lib'
import {TaskType} from '../shared/db/task'
import {IGarminDevice, ITaskSchedule} from '../shared/db'

export {TaskType}
export enum ThirdPartyTaskType {
  Dexcom = 'dexcom',
  GarminConnect = 'garmin_connect',
}

export interface TaskStateType {
  createdAt: string
  updatedAt: string
  id: string
  projectId: string
  methodId: string
  workspaceId: string
  index: number
  enabled: boolean
  color: string | null
  required: boolean
  scheduleRepeat: boolean
  scheduleDescription: string
  reminderList: TaskReminder[]
  scheduleList: ITaskSchedule[]
  type: TaskType | ThirdPartyTaskType
  taskSetId: string | null
  taskSetParentId: string | null
  todoId: string | null
  timerId: string | null
  timerAccId: string | null
  timerAcc: any | null // not sure the data schema
  questionnaireId: string | null
  garminDeviceId: string | null
  stopwatchGarminStreamId: string | null
  stopwatchMovesenseStreamId: string | null
  [type: string]: any
}

export interface TaskTempStateType extends TaskStateType {
  actionType: 'create' | 'update' | 'hide' | 'show'
  enabled: boolean
  existed: boolean
}

export type TaskContentName =
  | 'todo'
  | 'timer'
  | 'questionnaire'
  | 'garminDevice'
  | 'stopwatchMovesenseStream'
  | 'stopwatchGarminStream'
  | 'dexcom'

export const getTaskContentName = (taskType: TaskType | ThirdPartyTaskType): TaskContentName => {
  if (taskType == TaskType.StopwatchMovesenseStream || taskType == TaskType.StopwatchMovesenseLogData) {
    return 'stopwatchMovesenseStream'
  } else {
    return snakeToCamel(taskType) as TaskContentName
  }
}

export type EditingTask = (TaskStateType & TaskTempStateType) | Record<string, any>

export interface TaskRegularT {
  id: string
  updatedAt: string
  createdAt: string
  taskId: string
  projectId: string
  workspaceId: string
  name: string
  description: string
}

export type GarminDeviceSpecCopy = Omit<
  IGarminDevice,
  | 'accelerometerEnable'
  | 'batteryPercentageSupport'
  | 'id'
  | 'workspaceId'
  | 'projectId'
  | 'taskId'
  | 'createdAt'
  | 'updatedAt'
>

export interface TaskStopwatchMovesenseStreamT {
  createdAt?: string
  updatedAt?: string
  id?: string
  taskId?: string
  projectId?: string
  workspaceId?: string
  name: string
  description: string
  imuEnable: boolean
  imuHz: number
  accEnable: boolean
  accHz: number
  gyroscopeEnable: boolean
  gyroscopeHz: number
  magnetometerEnable: boolean
  magnetometerHz: number
  ecgEnable: boolean
  ecgHz: number
  rrEnable: boolean
  timeLimitSeconds: number
}

export type TaskStopwatchMovesenseStreamCopyT = Omit<
  TaskStopwatchMovesenseStreamT,
  'id' | 'taskId' | 'projectId' | 'workspaceId' | 'createdAt' | 'updatedAt' | 'name' | 'description'
>

export interface TaskStopwatchGarminStreamT {
  // TODO: check "?"
  createdAt?: string
  updatedAt?: string
  id?: string
  taskId?: string
  projectId?: string
  workspaceId?: string
  name: string
  description: string
  accEnable: boolean
  accHz: number
  timeLimitSeconds: number
}

export interface TaskDexcom {
  dexcomApp?: {[key: string]: any}
  name: string
  description: string
  investigator: string
  organization: string
  countries: string
  contactDescription: string
}

export type TaskStopwatchGarminStreamCopyT = Omit<
  TaskStopwatchGarminStreamT,
  'id' | 'taskId' | 'projectId' | 'workspaceId' | 'createdAt' | 'updatedAt' | 'name' | 'description'
>

export type TaskReminder = {
  type: 'date' | 'cron'
  value: string
}

export type TaskTypeForDevicePage =
  | 'garmin_device'
  | 'smartphone'
  | 'dexcomIntegrationForm'
  | 'dexcomSelectorPopup'
  | 'polar'
  | ''
export type TaskTypeForInstructionPage = TaskType.StopwatchMovesenseStream | TaskType.StopwatchGarminStream | ''

export interface Questionnaire {
  id: string
  methodId?: string
  updatedAt?: string
  createdAt?: string
  taskId?: string
  projectId?: string
  workspaceId?: string
  name?: string
  description?: string
  contentList?: ContentListItem[]
  sectionList?: Section[]
}

export interface Section {
  id?: string
  questionnaireId?: string
  description?: string
  legend: string
  index?: number
  questionList?: Question[]
}

export enum QuestionnaireQuestionType {
  Text = 'text',
  Textarea = 'textarea',
  Number = 'number',
  Date = 'date',
  Select = 'select',
  MultiSelect = 'multi_select',
  Checkbox = 'checkbox',
  Radio = 'radio',
  DateTime = 'date_time',
  Time = 'time',
}

export interface Question {
  id?: string
  sectionId?: string
  label: string
  type: QuestionnaireQuestionType
  index?: number
  description?: string
  required: boolean
  inputConfig: {
    options: Option[]
  }
}

export interface Option {
  name: string
  value?: string
}

export enum ContentListItemCategory {
  Section = 'section',
  Question = 'question',
}
export interface ContentListItem {
  id: string
  category: ContentListItemCategory
  sectionId?: string
}
