import {Adherence, TaskStateType, DigestDay, TaskType, getTaskContentName} from '../model'
import {colorTranslator} from '../store'
import {_, t} from '.'

export interface AdherenceDataItem {
  adherenceList: Adherence[]
  taskList: TaskStateType[]
  participantId: string
}

export interface DailySummaryAdherenceData {
  dehydratedTaskList: DehydratedTask[]
  dailySummaryAdherenceList: DailySummaryAdherenceItem[]
}

export interface DailySummaryAdherenceItem {
  done: number | null
  date: string
  taskName: string
  numberOfCompletions: number
}

interface DehydratedTask {
  id: string
  name: string
  color: string
  index: number
}

export class AdherenceDataTransformer {
  constructor(
    private readonly taskDataDates: {[key: string]: number[]} | undefined,
    private readonly taskList: TaskStateType[],
    private readonly participantAdherence: Adherence,
    private readonly garminConnectEnabled: boolean
  ) {}

  async toDailySummary(range: number[]): Promise<DailySummaryAdherenceData> {
    const dehydratedTaskList = this.dehydrateTaskList(this.taskList)
    const dailySummaryAdherenceList = this.participantAdherence ? 
      t.toYYMMDDListFromRange(range[0], range[1]).flatMap(yymmddIndex =>{
        return dehydratedTaskList.map((task) => {
          const digestDay = _.find(this.participantAdherence.digestDayList, ['yymmddIndex', yymmddIndex])
          const numberOfCompletions = this.calculateNumberOfCompletions(task.id, digestDay)
          const done = this.checkIfDone(task, yymmddIndex, digestDay)
          return {
            date: `${yymmddIndex}`,
            taskName: task.name,
            done,
            numberOfCompletions,
          }
        })
      }) : []

    return {
      dehydratedTaskList,
      dailySummaryAdherenceList,
    }
  }

  private calculateNumberOfCompletions(taskId: string, digestDay?: DigestDay) {

    return digestDay?.taskCompletionCountMap?.[taskId] || 0
  }

  private dehydrateTaskList(taskList: TaskStateType[]): DehydratedTask[] {

    const result = taskList
      .filter((task) => !!task.type && task.type !== TaskType.GarminDevice)
      .map((task, index) => ({
        id: task.id,
        name: task[getTaskContentName(task.type)].name,
        color: task.color ? `#${task.color}` : colorTranslator(index),
        index,
      }))
    const labfrontCompanionTask = _.find(
      taskList,
      ['type', TaskType.GarminDevice]
    )
    if (labfrontCompanionTask) {
      result.push({
        id: labfrontCompanionTask.id,
        name: 'Garmin Companion App',
        color: '#3455F4',
        index: result.length,
      })
    }
    if (this.garminConnectEnabled) {
      result.push({
        id: 'garminConnect',
        name: 'Garmin Connect App',
        color: '#1F1F1F',
        index: result.length,
      })
    }
    return result
  }

  

  private checkIfDone(task: DehydratedTask, yymmddIndex: number, digestDay?: DigestDay) {
    if (task.name === 'Garmin Companion App') {
      return digestDay?.garminDirectCollected ? task.index : null
    }
    if (task.name === 'Garmin Connect App') {
      return digestDay?.garminConnectSynced ? task.index : null
    }
    return this.taskDataDates?.[task.id]?.includes(yymmddIndex) ? task.index : null
  }
}
