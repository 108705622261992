import {Link} from 'react-router-dom'
import {isInBetaType, RIF, useCurrentProjectState} from '../../lib'
import {selectBatchData, selectTheme, useSelectSettings} from '../../store'
import {ButtonReverse} from '..'
import {Dispatch, SetStateAction} from 'react'
import {DownloadGreyIcon, DownloadIcon} from '../../asset/image'
import {ParticipantStateType} from '../../model'
import {BetaType} from '../../shared/db'

export interface ProjectSwitchBarProps {
  projectPanel: string
  setRenderDataDownloadPage: Dispatch<SetStateAction<boolean>>
}

export const ProjectSwitchBar = (props: ProjectSwitchBarProps) => {
  const {pad, color, fontWeight} = selectTheme()

  const {projectPanel, setRenderDataDownloadPage, ...rest} = props

  const {projectId, project} = useCurrentProjectState()
  const batchId = project?.batchList?.[0]?.id ?? ''
  const batchData = selectBatchData()
  const participantList: ParticipantStateType[] = batchData?.[batchId]?.participantList || []
  const noParticipants = participantList.length === 0

  const settings = useSelectSettings()

  return (
    <div
      {...rest}
      css={{
        width: '100%',
        height: '56px',
        boxSizing: 'border-box',
        backgroundColor: color.white,
        marginTop: '50px',
        padding: '0 30px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: `1px solid ${color.grey_100}`,
        position: 'relative',
        zIndex: 98,
        boxShadow: '0px 2px 10px 0px #0000000A',
      }}
    >
      <div
        css={{
          height: '100%',
          boxSizing: 'border-box',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Link to={`/dashboard/${projectId}`}>
          <Tab name='Overview' {...{projectPanel}} />
        </Link>
        <Link to={`/participants/${projectId}`}>
          <Tab name='Participants' {...{projectPanel}} />
        </Link>
        <Link to={`/adherence/${projectId}`}>
          <Tab name='Adherence' {...{projectPanel}} />
        </Link>
        {RIF(
          isInBetaType({betaType: BetaType.PublicBeta, settings}),
          <Link to={`/data_visualization/${projectId}/${participantList[0]?.id}`}>
            <Tab beta={true} name='Data Visualization' {...{projectPanel}} />
          </Link>,
        )}
        {RIF(
          isInBetaType({betaType: BetaType.ClosedBeta, settings}),
          <Link to={`/data_analysis/correlation /${projectId}`}>
            <Tab beta={true} name='Data Analysis' {...{projectPanel}} />
          </Link>,
        )}
        <Link to={`/settings/${projectId}`}>
          <Tab name='Settings' {...{projectPanel}} />
        </Link>
      </div>
      {/* download section */}
      {/* <DataDownloadSection /> */}
      <ButtonReverse
        onClick={() => setRenderDataDownloadPage(true)}
        disabled={noParticipants}
        btnPadding='medium'
        btnColor='primary'
        css={{position: 'relative'}}
        children={
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              color: noParticipants ? color.disabled : color.primary,
              fontWeight: fontWeight.bold,
            }}
          >
            <img width='20' css={{marginRight: pad.small}} src={noParticipants ? DownloadGreyIcon : DownloadIcon} />
            Download Data
          </div>
        }
      />
    </div>
  )
}

const Tab = (props: {name: string; projectPanel: string; beta?: boolean}) => {
  const {color, fontWeight, fontSize} = selectTheme()
  const active = props.projectPanel === props.name
  return (
    <div
      css={{
        fontWeight: fontWeight.bold,
        height: '56px',
        display: 'flex',
        alignItems: 'center',
        marginRight: '40px',
        cursor: 'pointer',
        color: `${active ? color.primary : color.black}`,
        borderBottom: `${active ? '2px' : '0px'} ${color.primary} solid`,
        ':hover': {
          color: color.primary,
        },
      }}
    >
      {props.name}
      {RIF(
        props.beta,
        <p
          css={{
            padding: '2px 6px',
            borderRadius: '2px',
            marginLeft: '8px',
            backgroundColor: color.grey_50,
            color: color.grey_400,
            fontWeight: fontWeight.thick,
            fontSize: fontSize.h7,
          }}
        >
          Beta
        </p>,
      )}
    </div>
  )
}
