import {RequestVariablesId, TrendResultOLSData, Variable} from '../../../shared/analysis'
import ReactGridLayout, {WidthProvider} from 'react-grid-layout'
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {DateObject} from 'react-multi-date-picker'
import {selectBatchData, selectTheme} from '../../../store'
import {v4} from 'uuid'
import _ from 'lodash'
import {getVariableDisplayName, RIF, useCurrentProjectState} from '../../../engine'
import {IParticipant} from '../../../shared/db'
import {VariableTag} from '../../atoms'

const ReactGridLayoutWidthProvider = WidthProvider(ReactGridLayout)

enum DataFrameTableItemType {
  Blank = 'blank',
  Header = 'header',
  Value = 'value',
}

interface DataFrameTableItem {
  dataGrid: {
    x: number
    y: number
    w: number
    h: number
    static: boolean
  }
  value: string
  type: DataFrameTableItemType
  coord?: RequestVariablesId
  identifier?: string
}

interface TrendDataFrameTrendCoord {
  individual: string | null
  group: string | null
  duration: string | null
}

const TrendDataFrameTrendTabList = (props: {
  tabList: TrendDataFrameTrendCoord[]
  participantInsigniaTable: {[key: string]: string}
  coord: TrendDataFrameTrendCoord
  setCoord: Dispatch<SetStateAction<TrendDataFrameTrendCoord>>
  duration: [DateObject, DateObject]
}) => {
  const {setCoord, tabList, coord, participantInsigniaTable, duration} = props
  const {color, fontSize, fontWeight} = selectTheme()

  const getTabText = (tab: TrendDataFrameTrendCoord, index: number): string => {
    if (tab.duration) {
      if (tab.individual) {
        return `${participantInsigniaTable[tab.individual]} (${tab.duration})`
      }
      if (tab.group) {
        return `${tab.group} (${tab.duration})`
      }
      return tab.duration
    } else {
      if (tab.individual) {
        return participantInsigniaTable[tab.individual]
      } else if (tab.group) {
        return tab.group
      }
    }
    return `Sample ${index + 1}`
  }
  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'flex-end',
      }}
    >
      {tabList.map((tab, index) => (
        <button
          key={v4()}
          onClick={() => {
            setCoord(tab)
          }}
          css={{
            border: `2px solid ${color.border._80}`,
            borderBottom: 'none',
            borderRadius: '8px 8px 0px 0px',
            padding: _.isEqual(tab, coord) ? '8px 24px' : '4px 24px',
            backgroundColor: _.isEqual(tab, coord) ? color.white : color.surface.grey.dark,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: fontSize.h7,
            fontWeight: fontWeight.bold,
            color: color.textIcon.light,
            cursor: 'pointer',
          }}
        >
          {getTabText(tab, index)}
        </button>
      ))}
    </div>
  )
}

export const TrendDataFrame = (props: {
  data: TrendResultOLSData[]
  // displayName: (identifier: string) => string
  // onClickCell: (coord: TrendCoordinate) => void
  displayingVariableList: Variable[]
  duration: [DateObject, DateObject]
  // selected?: TrendCoordinate
}) => {
  const {color, fontSize} = selectTheme()
  const {data, displayingVariableList, duration} = props
  const {project} = useCurrentProjectState()
  const batchId: string | undefined = project?.batchList?.[0]?.id
  const participantList: IParticipant[] = selectBatchData()?.[batchId ?? '']?.participantList || []
  const defaultCoord = {individual: null, group: null, duration: null}
  const variablesCount = displayingVariableList.length
  const tableDefault: DataFrameTableItem[] = [
    {
      dataGrid: {x: 0, y: 0, w: 1, h: 1, static: true},
      value: '',
      type: DataFrameTableItemType.Blank,
    },
    {
      dataGrid: {x: 1, y: 0, w: 1, h: 1, static: true},
      value: '',
      type: DataFrameTableItemType.Blank,
    },
    {
      dataGrid: {x: 2, y: 0, w: 1, h: 1, static: true},
      value: 'coef.',
      type: DataFrameTableItemType.Header,
    },
    {
      dataGrid: {x: 3, y: 0, w: 1, h: 1, static: true},
      value: 'SEM',
      type: DataFrameTableItemType.Header,
    },
    {
      dataGrid: {x: 4, y: 0, w: 1, h: 1, static: true},
      value: '95% CI',
      type: DataFrameTableItemType.Header,
    },
    {
      dataGrid: {x: 5, y: 0, w: 1, h: 1, static: true},
      value: 't',
      type: DataFrameTableItemType.Header,
    },
    {
      dataGrid: {x: 6, y: 0, w: 1, h: 1, static: true},
      value: 'p',
      type: DataFrameTableItemType.Header,
    },
    {
      dataGrid: {x: 7, y: 0, w: 1, h: 1, static: true},
      value: 'Adj. p(BH)',
      type: DataFrameTableItemType.Header,
    },
    {
      dataGrid: {x: 0, y: 1, w: 1, h: variablesCount, static: true},
      value: 'Intercept',
      type: DataFrameTableItemType.Header,
    },
    {
      dataGrid: {x: 0, y: 1 + variablesCount, w: 1, h: variablesCount, static: true},
      value: 'Slope',
      type: DataFrameTableItemType.Header,
    },
  ]
  const [participantInsigniaTable, setParticipantInsigniaTable] = useState<{[key: string]: string}>({})
  const [coord, setCoord] = useState<TrendDataFrameTrendCoord>(defaultCoord)
  const [tabList, setTabList] = useState<TrendDataFrameTrendCoord[]>([])
  const [table, setTable] = useState<DataFrameTableItem[]>(tableDefault)
  const [header, setHeader] = useState<DataFrameTableItem[]>([])

  useEffect(() => {
    if (!displayingVariableList?.length) return setHeader([])
    setHeader(
      displayingVariableList.flatMap((variable, index) => {
        return [
          {
            dataGrid: {x: 1, y: 1 + index, w: 1, h: 1, static: true},
            value: getVariableDisplayName(variable),
            type: DataFrameTableItemType.Header,
            identifier: variable.identifier,
          },
          {
            dataGrid: {x: 1, y: 1 + index + variablesCount, w: 1, h: 1, static: true},
            value: getVariableDisplayName(variable),
            type: DataFrameTableItemType.Header,
            identifier: variable.identifier,
          },
        ]
      }),
    )
  }, [displayingVariableList])

  useEffect(() => {
    if (!data?.length) return setTable([])
    const tempTable = [...tableDefault]
    const result = data
      .filter((d) => {
        return _.isEqual(d.coord, coord) || _.isEqual(coord, defaultCoord)
      })
      .sort((a, b) => {
        const lastCharA = a.variable_name.slice(-1)
        const lastCharB = b.variable_name.slice(-1)
        return lastCharA.localeCompare(lastCharB)
      })
      .flatMap((d, index) => {
        const {intercept, slope} = d
        return [
          {
            dataGrid: {x: 2, y: 1 + index, w: 1, h: 1, static: true},
            value: intercept.coef?.toFixed(2) ?? 'No Data',
            type: DataFrameTableItemType.Value,
          },
          {
            dataGrid: {x: 3, y: 1 + index, w: 1, h: 1, static: true},
            value: intercept.sem?.toFixed(2) ?? 'No Data',
            type: DataFrameTableItemType.Value,
          },
          {
            dataGrid: {x: 4, y: 1 + index, w: 1, h: 1, static: true},
            value: `(${intercept.ci_95_low?.toFixed(2) ?? 'No Data'}, ${
              intercept.ci_95_high?.toFixed(2) ?? 'No Data'
            })`,
            type: DataFrameTableItemType.Value,
          },
          {
            dataGrid: {x: 5, y: 1 + index, w: 1, h: 1, static: true},
            value: intercept.t_value?.toFixed(2) ?? 'No Data',
            type: DataFrameTableItemType.Value,
          },
          {
            dataGrid: {x: 6, y: 1 + index, w: 1, h: 1, static: true},
            value: intercept.p_value?.toFixed(2) ?? 'No Data',
            type: DataFrameTableItemType.Value,
          },
          {
            dataGrid: {x: 7, y: 1 + index, w: 1, h: 1, static: true},
            value: intercept.bh_adjusted_p_value?.toFixed(2) ?? 'No Data',
            type: DataFrameTableItemType.Value,
          },
          {
            dataGrid: {x: 2, y: 1 + index + variablesCount, w: 1, h: 1, static: true},
            value: slope.day?.coef?.toFixed(2) ?? 'No Data',
            type: DataFrameTableItemType.Value,
          },
          {
            dataGrid: {x: 3, y: 1 + index + variablesCount, w: 1, h: 1, static: true},
            value: slope.day?.sem?.toFixed(2) ?? 'No Data',
            type: DataFrameTableItemType.Value,
          },
          {
            dataGrid: {x: 4, y: 1 + index + variablesCount, w: 1, h: 1, static: true},
            value: `(${slope.day?.ci_95_low?.toFixed(2) ?? 'No Data'}, ${
              slope.day?.ci_95_high?.toFixed(2) ?? 'No Data'
            })`,
            type: DataFrameTableItemType.Value,
          },
          {
            dataGrid: {x: 5, y: 1 + index + variablesCount, w: 1, h: 1, static: true},
            value: slope.day?.t_value?.toFixed(2) ?? 'No Data',
            type: DataFrameTableItemType.Value,
          },
          {
            dataGrid: {x: 6, y: 1 + index + variablesCount, w: 1, h: 1, static: true},
            value: slope.day?.p_value?.toFixed(2) ?? 'No Data',
            type: DataFrameTableItemType.Value,
          },
          {
            dataGrid: {x: 7, y: 1 + index + variablesCount, w: 1, h: 1, static: true},
            value: slope.day?.bh_adjusted_p_value?.toFixed(2) ?? 'No Data',
            type: DataFrameTableItemType.Value,
          },
        ]
      })
    setTable([...tableDefault, ...header, ...result])
  }, [data, coord])

  useEffect(() => {
    if (!data.length) return
    const durationList: Set<string> = new Set()
    const individualList: Set<string> = new Set()
    const groupList: Set<string> = new Set()
    data.forEach(({coord}) => {
      const {duration, individual, group} = coord
      if (duration) durationList.add(duration)
      if (individual) {
        individualList.add(individual)
        const findResult = _.find(participantList, ['id', individual])
        setParticipantInsigniaTable((prev) => {
          return {
            ...prev,
            [individual]: findResult?.insignia ?? 'Unknown',
          }
        })
      }
      if (group) groupList.add(group)
    })
    const tempTabList: TrendDataFrameTrendCoord[] = []
    if (durationList.size > 1) {
      // multiple duration
      if (individualList.size > 1) {
        // multiple individual
        for (const individual of individualList) {
          for (const duration of durationList) {
            tempTabList.push({...defaultCoord, individual, duration})
          }
        }
      } else if (groupList.size > 1) {
        // multiple group
        for (const group of groupList) {
          for (const duration of durationList) {
            tempTabList.push({...defaultCoord, group, duration})
          }
        }
      } else {
        // single individual or group
        for (const duration of durationList) {
          tempTabList.push({...defaultCoord, duration})
        }
      }
    } else {
      // single duration
      if (individualList.size > 1) {
        // multiple individual
        for (const individual of individualList) {
          tempTabList.push({...defaultCoord, individual})
        }
      } else if (groupList.size > 1) {
        // multiple group
        for (const group of groupList) {
          tempTabList.push({...defaultCoord, group})
        }
      }
    }
    if (!tempTabList.length) tempTabList.push(defaultCoord)
    setTabList(tempTabList)
    setCoord(tempTabList[0])
  }, [data])

  return (
    <div
      css={{
        marginTop: '32px',
      }}
    >
      {RIF(
        tabList.length > 1,
        <TrendDataFrameTrendTabList
          {...{
            tabList,
            participantInsigniaTable,
            coord,
            setCoord,
            duration,
          }}
        />,
      )}
      <ReactGridLayoutWidthProvider
        css={{
          border: `1px solid ${color.border._80}`,
          borderRadius: '5px',
          overflow: 'hidden',
          backgroundColor: color.white,
        }}
        cols={8}
        rowHeight={30}
        margin={[0, 0]}
      >
        {table.map(({dataGrid, value, type, coord, identifier}) => {
          return (
            <div
              css={{
                border: `.5px solid ${color.border._80}`,
                backgroundColor: type === DataFrameTableItemType.Header ? color.surface.grey.light : color.white,
                boxSizing: 'border-box',
                display: 'flex',
                alignItems: 'center',
                padding: '8px',
              }}
              key={`${dataGrid.x}-${dataGrid.y}`}
              data-grid={dataGrid}
            >
              {RIF(identifier, <VariableTag identifier={identifier} />)}
              <p
                css={{
                  fontSize: fontSize.h7,
                  color: color.textIcon.secondary,
                }}
              >
                {value}
              </p>
            </div>
          )
        })}
      </ReactGridLayoutWidthProvider>
    </div>
  )
}
