import {useEffect, useState} from 'react'
import {
  createDispatchActions,
  selectParticipantDailyData,
  selectTheme,
  TypeDataDateState,
} from '../../../store'
import {RequestResult, t} from '../../../lib'
import {DailyDataType} from '../../../store/db/db_data_setter'
import {getDailyDataFromDb} from '../../../store/db/db_data_getter'
import {GenericBoxChartCardProps, ViolinBoxChart} from '../template/violin_box_chart'
import {GarminDeviceDailySummary} from '../../../shared/mongo'

export interface ParticipantGarminDirectDailySummaryChartSeriesProps {
  participantId: string
  dataDateRange: number[]
  cardHeight: string
  cardWidth: string
}

export const ParticipantGarminDirectDailySummaryChartSeries = (
  props: ParticipantGarminDirectDailySummaryChartSeriesProps,
) => {
  const {participantId, dataDateRange, cardHeight, cardWidth} = props
  const [yymmddIndexStart, yymmddIndexEnd] = dataDateRange
  const {doREQUEST_PARTICIPANT_GARMIN_DIRECT_DAILY_SUMMARY}: any = createDispatchActions()
  const [requestResult, setRequestResult] = useState<RequestResult | null>(null)
  const {color, fontWeight} = selectTheme()

  const participantDailyData = selectParticipantDailyData(participantId)
  const [stepDataList, setStepDataList] = useState<number[]>()
  const [stressDataList, setStressDataList] = useState<number[]>()
  const [hrDataList, setHrDataList] = useState<number[]>()
  const [spo2DataList, setSpo2DataList] = useState<number[]>()

  /* ------------------ reducer & store basic state ------------------ */
  useEffect(() => {
    if (participantId.length > 0) {
      const fetchRange = t.toYYMMDDListFromRange(yymmddIndexStart, yymmddIndexEnd) //.filter((index) => !fetchedIndexSet.has(index))
      if (fetchRange.length) {
        doREQUEST_PARTICIPANT_GARMIN_DIRECT_DAILY_SUMMARY({
          setRequestResult,
          payload: {
            participantId: participantId,
            yymmddIndexStart: fetchRange[0],
            yymmddIndexEnd: fetchRange[fetchRange.length - 1],
          },
        })
      }
    }
  }, [participantId, dataDateRange])

  useEffect(() => {
    // if (requestResult?.success) {
    //   const [yymmddIndexStart, yymmddIndexEnd] = dataDateRange
    //   fetchDataFromDbToPlot(participantDailyData, participantId, yymmddIndexStart, yymmddIndexEnd)
    //   // setRequestResult(null)
    // }

    if (participantDailyData) {
      const [yymmddIndexStart, yymmddIndexEnd] = dataDateRange
      fetchDataFromDbToPlot(participantDailyData, participantId, yymmddIndexStart, yymmddIndexEnd)
    }
  }, [participantDailyData, participantId, dataDateRange])

  const fetchDataFromDbToPlot = async (
    participantDailyData: TypeDataDateState,
    participantId: string,
    yymmddIndexStart: number,
    yymmddIndexEnd: number,
  ) => {
    const garminDailyDataMap = participantDailyData?.[DailyDataType.GarminDirectDailySummary]
    if (garminDailyDataMap) {
      const setpData: number[] = []
      const stressData: number[] = []
      const hrData: number[] = []
      const spo2Data: number[] = []

      for (const [yymmddIndex, dbState] of Object.entries(garminDailyDataMap)) {
        const yymmdd = +yymmddIndex
        if (yymmdd >= yymmddIndexStart && yymmdd <= yymmddIndexEnd) {
          if (dbState) {
            const dbContent = await getDailyDataFromDb(participantId, DailyDataType.GarminDirectDailySummary, yymmdd)
            if (dbContent?.data) {
              const summary = dbContent.data as GarminDeviceDailySummary
              if (summary.step) {
                const value = summary.step.total
                setpData.push(value)
              }
              if (summary.stress) {
                const value = Math.floor(summary.stress.mean)
                stressData.push(value)
              }
              if (summary.heartRate) {
                const value = Math.floor(summary.heartRate.mean)
                hrData.push(value)
              }
              if (summary.pulseOx) {
                const value = Math.floor(summary.pulseOx.mean)
                spo2Data.push(value)
              }
            }
          }
        }
      }
      setStepDataList(setpData)
      setStressDataList(stressData)
      setHrDataList(hrData)
      setSpo2DataList(spo2Data)
    }
  }

  const stepBoxChart = () => {
    const boxChartCardProps: GenericBoxChartCardProps = {
      config: {
        dataName: 'Average',
        dataUnit: 'steps',
        chartCardConfig: {
          height: cardHeight,
          width: cardWidth,
          title: 'Total Step',
          hintDescription: '',
        },
        chartConfig: {},
      },
      dataSource: stepDataList,
    }

    return <ViolinBoxChart {...boxChartCardProps} />
  }

  const stressBoxChart = () => {
    const boxChartCardProps: GenericBoxChartCardProps = {
      config: {
        dataName: 'Average',
        dataUnit: 'score',
        chartCardConfig: {
          height: cardHeight,
          width: cardWidth,
          title: 'Stress',
          hintDescription: '',
        },
        chartConfig: {},
      },
      dataSource: stressDataList,
    }

    return <ViolinBoxChart {...boxChartCardProps} />
  }

  const hrBoxChart = () => {
    const boxChartCardProps: GenericBoxChartCardProps = {
      config: {
        dataName: 'Average',
        dataUnit: 'bpm',
        chartCardConfig: {
          height: cardHeight,
          width: cardWidth,
          title: 'Heart Rate',
          hintDescription: '',
        },
        chartConfig: {},
      },
      dataSource: hrDataList,
    }

    return <ViolinBoxChart {...boxChartCardProps} />
  }

  const spo2BoxChart = () => {
    const boxChartCardProps: GenericBoxChartCardProps = {
      config: {
        dataName: 'Average',
        dataUnit: '%',
        chartCardConfig: {
          height: cardHeight,
          width: cardWidth,
          title: 'Spo2',
          hintDescription: '',
        },
        chartConfig: {},
      },
      dataSource: spo2DataList,
    }
    return <ViolinBoxChart {...boxChartCardProps} />
  }

  const titleSection = (
    <div
      css={{
        width: '100%',
        padding: '12px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        fontWeight: fontWeight.base,
        fontSize: '18px',
      }}
    >
      <p
        css={{
          fontWeight: fontWeight.thick,
          marginRight: '6px',
          whiteSpace: 'nowrap',
        }}
      >
        Garmin Direct Summary
      </p>
      {/* <Tooltip content={''} /> */}
    </div>
  )

  return (
    <div>
      {titleSection}
      <div
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
          height: '350px',
        }}
      >
        {stepBoxChart()}
        {hrBoxChart()}
        {stressBoxChart()}
        {spo2BoxChart()}
      </div>
    </div>
  )
}
