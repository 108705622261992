import {Dispatch, SetStateAction} from 'react'
import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
  RequestResult,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE, doANALYTIC_VARIABLE_LIST_SET} from '../state'
import {getToken} from './token_fetcher'

export const REQUEST_ANALYTIC_VARIABLE_LIST_FETCH = 'REQUEST_ANALYTIC_VARIABLE_LIST_FETCH'
export const doREQUEST_ANALYTIC_VARIABLE_LIST_FETCH = createDoRequestAction(REQUEST_ANALYTIC_VARIABLE_LIST_FETCH)

export const requestAnalyticVariableListFetchActionCreators = {
  doREQUEST_ANALYTIC_VARIABLE_LIST_FETCH,
}

interface RequestAnalyticVariableListFetchPayload {
  requestId: string
  projectId: string
}

export function* requestAnalyticVariableListFetch({
  payload,
  type,
  setRequestResult,
}: {
  payload: RequestAnalyticVariableListFetchPayload
  type: string
  setRequestResult: Dispatch<SetStateAction<RequestResult>>
}): SagaIterator {
  debug('saga*requestAnalyticVariableListFetch')
  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        projectId: v.string().uuid().exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: REQUEST_ANALYTIC_VARIABLE_LIST_FETCH,
          requestId: payload.requestId,
          error,
        },
        setRequestResult,
      ),
    )
  }

  const {projectId} = payload
  const accessToken = yield call(getToken)
  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/analytic-variable-list-fetch`,
    accessToken,
    data: {
      projectId,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: REQUEST_ANALYTIC_VARIABLE_LIST_FETCH,
          fromPayload: payload,
          requestId: payload.requestId,
          url: '/v1/web/analytic-variable-list-fetch',
          ...result,
          error,
        },
        setRequestResult,
      ),
    )
  }

  yield put(
    doANALYTIC_VARIABLE_LIST_SET({
      projectId,
      analyticVariableList: result.payload,
    }),
  )

  yield put(
    doREQUEST_COMPLETE(
      {
        fromType: REQUEST_ANALYTIC_VARIABLE_LIST_FETCH,
        requestId: payload.requestId,
        ...result,
      },
      setRequestResult,
    ),
  )
}

export function* analyticVariableListFetchSaga() {
  yield takeEvery(REQUEST_ANALYTIC_VARIABLE_LIST_FETCH, requestAnalyticVariableListFetch)
}
