import {useEffect, useState} from 'react'
import {selectTheme, createDispatchActions, selectMethod, selectProjectSettings} from '../../store'
import {ButtonCancel, ButtonReverse, Button, ButtonAdd, DragHandle, PopupConfirmUnsavedChanges} from '..'
import {TaskStateType, TaskType, TaskStopwatchMovesenseStreamT} from '../../model'
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd'
import {_, RequestResult, RIF, t, useCurrentProjectState} from '../../lib'
import {LockIcon, CheckGreyIcon} from '../../asset/image'
import {Scrollbars} from 'react-custom-scrollbars-2'
import {
  VisualizerGraphResolution,
  VisualizerGraphDataType,
  createEmptyVisualizerSidebarSetting,
  IGarminDevice,
} from '../../shared/db'
import {VisualizerDurationResolution} from '../../lib/chart_data/model/VisualizerDurationResolution'

interface CustomizationPopupProps {
  handleCloseCustomizationPopup: () => void
  resolution: VisualizerGraphResolution | VisualizerDurationResolution
}

enum VisualizerResolutionTitleTranslator {
  Daily = 'Daily',
  MultiDay = 'Duration',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  TimeSeries = 'Time Series',
  EntireDuration = 'Entire Druation',
}

const DataTypeTranslator: Record<string, string> = {
  DexcomBloodGlucose: 'Dexcom',
  GarminDirectAccelerometerRaw: 'Garmin Direct Stream ACC',
  GarminDirectBBI: 'Garmin Direct BBI',
  GarminDirectActigraphyRaw: 'Garmin Direct Actigraphy',
  GarminDirectHeartRate: 'Garmin Direct Heart Rate',
  GarminDirectSpO2: 'Garmin Direct SpO2',
  GarminDirectStress: 'Garmin Direct Stress',
  GarminDirectSteps: 'Garmin Direct Steps',
  GarminConnectStress: 'Garmin Connect Stress',
  GarminConnectHeartRate: 'Garmin Connect Heart Rate',
  GarminConnectRestingHR: 'Garmin Connect Resting Heart Rate',
  GarminConnectBodyBattery: 'Garmin Connect Body Battery',
  GarminConnectSleepStaging: 'Garmin Connect Sleep',
  GarminConnectActivityEpochs: 'Garmin Connect Activity Epoch',
  GarminConnectSteps: 'Garmin Connect Steps',
  GarminConnectCalories: 'Garmin Connect Calories',
  GarminDeviceWearingTime: 'Garmin Connect Device Wearing Time',
  MovesenseIMU: 'Movesence IMU',
  MovesenseECGWaveform: 'Movesence ECG Waveform',
  MovesenseECGRR: 'Movesence ECG RR',
}

interface MovesenseDataSetting {
  imuEnabled?: boolean
  ecgEnabled?: boolean
  ecgRrEnabled?: boolean
  accEnabled?: boolean
  gyroEnabled?: boolean
  mangetoEnabled?: boolean
}

export const CustomizationPopup = (props: CustomizationPopupProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {handleCloseCustomizationPopup, resolution} = props
  const {doREQUEST_IDENTITY_PROJECT_SETTINGS_CREATE, doREQUEST_IDENTITY_PROJECT_SETTINGS_UPDATE}: any =
    createDispatchActions()
  const {projectId} = useCurrentProjectState()
  const projectSettings = projectId ? (selectProjectSettings()[projectId] as any) : undefined // FIXME: temporary cast to any to avoid invalid key type warning
  const methodState = selectMethod()
  const dexcomIntegrationId = methodState.dexcomIntegrationId
  // const garminConnectEnable = methodState?.garminConnectEnable || false
  const garminDeviceTask: TaskStateType | undefined = _.find(methodState?.taskList, {type: TaskType.GarminDevice})
  const garminDeviceTaskDetail: IGarminDevice | null = garminDeviceTask?.garminDevice || null
  const garminStreamTask: TaskStateType | undefined = _.find(methodState?.taskList, {
    type: TaskType.StopwatchGarminStream,
  })
  const garminConnectEnabled: boolean = methodState?.garminConnectEnable
  const movesenseStreamTask: TaskStateType[] | undefined = methodState?.taskList.filter((task) => {
    return task.type === TaskType.StopwatchMovesenseStream || task.type === TaskType.StopwatchMovesenseLogData
  })
  const movesenseDataConfig: MovesenseDataSetting = movesenseStreamTask.reduce((result, task) => {
    const setting = task.stopwatchMovesenseStream as TaskStopwatchMovesenseStreamT
    if (!result.imuEnabled) result.imuEnabled = setting.imuEnable
    if (!result.ecgEnabled) result.ecgEnabled = setting.ecgEnable
    if (!result.ecgRrEnabled) result.ecgRrEnabled = setting.rrEnable
    if (!result.accEnabled) result.accEnabled = setting.accEnable
    if (!result.gyroEnabled) result.gyroEnabled = setting.gyroscopeEnable
    if (!result.mangetoEnabled) result.mangetoEnabled = setting.magnetometerEnable

    return result
  }, {} as MovesenseDataSetting)

  const generateDefaultSettingsForTimeseries = () => {
    const result = []
    if (garminDeviceTaskDetail?.bbiEnable) {
      result.push({
        type: VisualizerGraphDataType.GarminDirectBBI,
        index: result.length,
        id: t.uuid(),
      })
    }
    if (garminDeviceTaskDetail?.stepsEnable) {
      result.push({
        type: VisualizerGraphDataType.GarminDirectSteps,
        index: result.length,
        id: t.uuid(),
      })
    }
    if (garminDeviceTaskDetail?.heartRateEnable) {
      result.push({
        type: VisualizerGraphDataType.GarminDirectHeartRate,
        index: result.length,
        id: t.uuid(),
      })
    }
    if (garminDeviceTaskDetail?.pulseOxEnable) {
      result.push({
        type: VisualizerGraphDataType.GarminDirectSpO2,
        index: result.length,
        id: t.uuid(),
      })
    }
    if (garminDeviceTaskDetail?.stressEnable) {
      result.push({
        type: VisualizerGraphDataType.GarminDirectStress,
        index: result.length,
        id: t.uuid(),
      })
    }
    if (garminDeviceTaskDetail?.actigraphyEnable) {
      result.push({
        type: VisualizerGraphDataType.GarminDirectActigraphyRaw,
        index: result.length,
        id: t.uuid(),
      })
    }

    return result
  }

  const defaultVisualizerSidebarSetting = {
    Daily: [
      {type: 'GarminConnectHeartRate', index: 0, id: t.uuid()},
      {type: 'GarminConnectStress', index: 1, id: t.uuid()},
      {type: 'GarminConnectSteps', index: 2, id: t.uuid()},
      {type: 'GarminConnectSleepStaging', index: 3, id: t.uuid()},
      {type: 'GarminConnectCalories', index: 4, id: t.uuid()},
      {type: 'GarminDeviceWearingTime', index: 5, id: t.uuid()},
    ],
    MultiDay: [
      {type: 'GarminConnectHeartRate', index: 0, id: t.uuid()},
      {type: 'GarminConnectStress', index: 1, id: t.uuid()},
      {type: 'GarminConnectSteps', index: 2, id: t.uuid()},
      {type: 'GarminConnectSleepStaging', index: 3, id: t.uuid()},
    ],
    Weekly: [
      {type: 'garmin_heart_rate', index: 0, id: t.uuid()},
      {type: 'garmin_stress', index: 1, id: t.uuid()},
      {type: 'garmin_steps', index: 2, id: t.uuid()},
      {type: 'garmin_sleeps', index: 3, id: t.uuid()},
    ],
    Monthly: [],
    TimeSeries: generateDefaultSettingsForTimeseries(),
    EntireDuration: [],
  }

  const [visualizerSidebarSetting, setVisualizerSidebarSetting] = useState<{type: string; index: number; id: string}[]>(
    defaultVisualizerSidebarSetting[resolution],
  )
  const [requestResult, setRequestResult] = useState<RequestResult | null>(null)
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true)
  const [renderConfirmUnsavedChangesPopup, setRenderConfirmUnsavedChangesPopup] = useState(false)

  useEffect(() => {
    if (!projectSettings?.visualizerSidebarSetting) return
    if (!resolution) return
    // FIXME: invalid typing of VisualizerDurationResolution?
    if (!projectSettings.visualizerSidebarSetting[resolution]) return
    setVisualizerSidebarSetting(
      projectSettings.visualizerSidebarSetting[resolution].map((item: {type: string; index: number}) => ({
        ...item,
        id: t.uuid(),
      })),
    )
  }, [projectSettings, resolution])

  useEffect(() => {
    // FIXME: invalid typing of VisualizerDurationResolution?
    setSaveBtnDisabled(
      _.isEqual(projectSettings?.visualizerSidebarSetting[resolution], visualizerSidebarSetting) ||
        (!projectSettings?.visualizerSidebarSetting[resolution] &&
          _.isEqual(
            visualizerSidebarSetting.map((c) => ({type: c.type, index: c.index})),
            defaultVisualizerSidebarSetting[resolution].map((c) => ({type: c.type, index: c.index})),
          )),
    )
  }, [visualizerSidebarSetting])

  useEffect(() => {
    if (requestResult?.success) {
      handleCloseCustomizationPopup()
    }
  }, [requestResult])

  const addGraphType = (type: string) => {
    const result = [...visualizerSidebarSetting]
    result.push({type, index: result.length, id: t.uuid()})
    setVisualizerSidebarSetting(result)
  }

  const removeGraphType = (index: number) => {
    const result = [...visualizerSidebarSetting]
      .filter((item) => item.index !== index)
      .map((item, i) => ({...item, index: i}))
    setVisualizerSidebarSetting(result)
  }

  const updateProjectSettings = () => {
    const newVisualizerSetting = visualizerSidebarSetting.map(({type}, index) => ({
      type,
      index,
    }))

    if (projectSettings) {
      doREQUEST_IDENTITY_PROJECT_SETTINGS_UPDATE({
        setRequestResult,
        payload: {
          identityProjectSettingsId: projectSettings.id,
          visualizerSidebarSetting: {
            ...projectSettings.visualizerSidebarSetting,
            [resolution]: newVisualizerSetting,
          },
        },
      })
    } else {
      doREQUEST_IDENTITY_PROJECT_SETTINGS_CREATE({
        setRequestResult,
        payload: {
          projectId,
          visualizerSidebarSetting: {
            ...createEmptyVisualizerSidebarSetting(),
            [resolution]: newVisualizerSetting,
          },
        },
      })
    }
  }

  const onDragEnd = (dropResult: DropResult) => {
    const {destination, source} = dropResult
    if (!destination) return
    if (destination.droppableId === source.droppableId && destination.index === source.index) return
    const result = JSON.parse(JSON.stringify([...visualizerSidebarSetting]))
    result.splice(destination.index, 0, result.splice(source.index, 1)[0])
    setVisualizerSidebarSetting(
      result.map((c: {id: string; type: string; index: number}, i: number) => ({...c, index: i})),
    )
  }

  const handleCloseAction = () => {
    if (!saveBtnDisabled) {
      if (!projectSettings?.visualizerSidebarSetting) {
        if (_.isEqual(visualizerSidebarSetting, defaultVisualizerSidebarSetting))
          return setRenderConfirmUnsavedChangesPopup(true)
      } else {
        if (
          !_.isEqual(
            visualizerSidebarSetting.map((c) => ({type: c.type, index: c.index})),
            projectSettings.visualizerSidebarSetting[resolution],
          )
        )
          return setRenderConfirmUnsavedChangesPopup(true)
      }
    }
    handleCloseCustomizationPopup()
  }

  const graphDataItem = (dataType: VisualizerGraphDataType) => {
    return (
      <VisibleGraphDataListItem
        visualizerSidebarSetting={visualizerSidebarSetting}
        visibleGraphDataType={dataType}
        addGraphType={addGraphType}
      />
    )
  }

  const getGarminDirectGraphDataTypeList = (scope: VisualizerGraphDataType[]) => {
    const result = []
    if (garminDeviceTaskDetail?.bbiEnable) result.push(VisualizerGraphDataType.GarminDirectBBI)
    if (garminDeviceTaskDetail?.stepsEnable) result.push(VisualizerGraphDataType.GarminDirectSteps)
    if (garminDeviceTaskDetail?.heartRateEnable) result.push(VisualizerGraphDataType.GarminDirectHeartRate)
    if (garminDeviceTaskDetail?.pulseOxEnable) result.push(VisualizerGraphDataType.GarminDirectSpO2)
    if (garminDeviceTaskDetail?.actigraphyEnable) result.push(VisualizerGraphDataType.GarminDirectActigraphyRaw)
    if (garminDeviceTaskDetail?.stressEnable) result.push(VisualizerGraphDataType.GarminDirectStress)
    if (garminStreamTask) result.push(VisualizerGraphDataType.GarminDirectAccelerometerRaw)
    return result.filter((item) => scope.includes(item))
  }

  const getMovesenseGraphDataTypeList = (scope: VisualizerGraphDataType[]) => {
    const result = []
    if (movesenseDataConfig?.ecgRrEnabled) result.push(VisualizerGraphDataType.MovesenseECGRR)
    if (movesenseDataConfig?.imuEnabled) result.push(VisualizerGraphDataType.MovesenseIMU)
    if (movesenseDataConfig?.ecgEnabled) result.push(VisualizerGraphDataType.MovesenseECGWaveform)
    return result.filter((item) => scope.includes(item))
  }

  const dataSelectionTitleStyle = {
    fontWeight: fontWeight.thick,
    fontSize: fontSize.h7,
    marginBottom: '8px',
  }

  const dataSelectionContainerStyle = {
    marginBottom: '32px',
    border: `1px solid ${color.grey_160}`,
    borderRadius: '5px',
    overflow: 'hidden',
  }

  const dexcomSection = (
    <div>
      <p css={dataSelectionTitleStyle}>Dexcom</p>
      <div css={dataSelectionContainerStyle}>{graphDataItem(VisualizerGraphDataType.DexcomBloodGlucose)}</div>
    </div>
  )

  const garminDirectSection = (
    <div>
      <p css={dataSelectionTitleStyle}>Garmin</p>
      <div css={dataSelectionContainerStyle}>
        {RIF(
          resolution === VisualizerGraphResolution.Daily || resolution === VisualizerGraphResolution.Weekly,
          getGarminDirectGraphDataTypeList([
            VisualizerGraphDataType.GarminDirectSteps,
            VisualizerGraphDataType.GarminDirectHeartRate,
            VisualizerGraphDataType.GarminDirectSpO2,
            VisualizerGraphDataType.GarminDirectStress,
          ]).map(graphDataItem),
        )}
        {RIF(
          resolution === VisualizerGraphResolution.TimeSeries,
          getGarminDirectGraphDataTypeList([
            VisualizerGraphDataType.GarminDirectBBI,
            VisualizerGraphDataType.GarminDirectSteps,
            VisualizerGraphDataType.GarminDirectHeartRate,
            VisualizerGraphDataType.GarminDirectSpO2,
            VisualizerGraphDataType.GarminDirectStress,
            VisualizerGraphDataType.GarminDirectActigraphyRaw,
            VisualizerGraphDataType.GarminDirectAccelerometerRaw,
          ]).map(graphDataItem),
        )}
      </div>
    </div>
  )

  const garminConnectSection = (
    <div>
      <p css={dataSelectionTitleStyle}>Garmin Connect</p>
      <div css={dataSelectionContainerStyle}>
        {RIF(
          resolution === VisualizerGraphResolution.Daily,
          [
            VisualizerGraphDataType.GarminConnectStress,
            VisualizerGraphDataType.GarminConnectHeartRate,
            VisualizerGraphDataType.GarminConnectSleepStaging,
            VisualizerGraphDataType.GarminConnectSteps,
            VisualizerGraphDataType.GarminConnectCalories,
            VisualizerGraphDataType.GarminDeviceWearingTime,
          ].map(graphDataItem),
        )}
        {RIF(
          resolution === VisualizerGraphResolution.Weekly,
          [
            VisualizerGraphDataType.GarminConnectStress,
            VisualizerGraphDataType.GarminConnectHeartRate,
            VisualizerGraphDataType.GarminConnectSleepStaging,
            VisualizerGraphDataType.GarminConnectSteps,
            // VisualizerGraphDataType.GarminConnectCalories,
            // VisualizerGraphDataType.GarminDeviceWearingTime,
          ].map(graphDataItem),
        )}
        {RIF(
          resolution === VisualizerGraphResolution.TimeSeries,
          [
            VisualizerGraphDataType.GarminConnectStress,
            VisualizerGraphDataType.GarminConnectHeartRate,
            VisualizerGraphDataType.GarminConnectBodyBattery,
            VisualizerGraphDataType.GarminConnectSteps,
            // VisualizerGraphDataType.Spo2, // lack of this
          ].map(graphDataItem),
        )}
      </div>
    </div>
  )

  const movesenseSection = (
    <div>
      {RIF(
        resolution === VisualizerGraphResolution.TimeSeries,
        <div>
          <p css={dataSelectionTitleStyle}>Movesense</p>
          <div css={dataSelectionContainerStyle}>
            {getMovesenseGraphDataTypeList([
              VisualizerGraphDataType.MovesenseECGRR,
              VisualizerGraphDataType.MovesenseIMU,
              VisualizerGraphDataType.MovesenseECGWaveform,
            ]).map(graphDataItem)}
          </div>
        </div>,
      )}
    </div>
  )

  const actigraphyAnalysisSection = (
    <div>
      <p css={dataSelectionTitleStyle}>Actigraphy Analysis</p>
      <div css={dataSelectionContainerStyle}>{graphDataItem(VisualizerGraphDataType.AnalysisSleepScore)}</div>
    </div>
  )

  return (
    <div
      css={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '105',
        background: '#00000040',
      }}
    >
      {RIF(
        renderConfirmUnsavedChangesPopup,
        <PopupConfirmUnsavedChanges
          closeAction={() => {
            setRenderConfirmUnsavedChangesPopup(false)
          }}
          discardAction={handleCloseCustomizationPopup}
          saveAction={updateProjectSettings}
        />,
      )}
      <div
        css={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <div
          css={{
            width: '552px',
            height: '100%',
            background: color.white,
            padding: '24px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ButtonCancel onClick={handleCloseAction} />
          <p
            css={{
              fontWeight: fontWeight.thick,
              fontSize: fontSize.h5,
              marginTop: '16px',
              marginBottom: '8px',
            }}
          >
            Customize Visualizer Dashboard - {VisualizerResolutionTitleTranslator[resolution]}
          </p>
          <p
            css={{
              fontSize: fontSize.h6,
              color: color.grey_600,
              marginBottom: '24px',
            }}
          >
            Customize this dashboard with the metrics that matter most to you and your team. To add a new data card,
            please select the metric from the list on the right.
          </p>
          <Scrollbars
            style={{
              flex: 1,
            }}
            autoHide={true}
            autoHideTimeout={200}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='visibleGraphDataTypeList'>
                {(provided) => {
                  return (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      css={{
                        width: 'calc(100% - 18px)',
                      }}
                    >
                      {visualizerSidebarSetting.map(({type, id}, index) => {
                        return (
                          <VisibleGraphDataDraggable
                            {...{
                              type,
                              index,
                              id,
                              key: id,
                              removeGraphType,
                            }}
                          />
                        )
                      })}
                      {provided.placeholder}
                    </div>
                  )
                }}
              </Droppable>
            </DragDropContext>
          </Scrollbars>
          <div
            css={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <ButtonReverse
              css={{marginRight: '16px'}}
              {...{
                children: 'Cancel',
                onClick: handleCloseAction,
              }}
            />
            <Button
              {...{
                children: 'Save Settings',
                onClick: updateProjectSettings,
                disabled: saveBtnDisabled,
              }}
            />
          </div>
        </div>
        <div
          css={{
            width: '336px',
            height: '100%',
            padding: '32px 24px',
            background: color.background,
          }}
        >
          <p
            css={{
              fontWeight: fontWeight.thick,
              fontSize: fontSize.h6,
              marginBottom: '4px',
            }}
          >
            All Metrics
          </p>
          <p
            css={{
              fontSize: fontSize.h7,
              color: color.grey_600,
              marginBottom: '32px',
            }}
          >
            Add the metrics from the following options to your Visualizer Dashboard based on your need.
          </p>

          {RIF(dexcomIntegrationId, dexcomSection)}
          {RIF(garminConnectEnabled, garminConnectSection)}
          {RIF(garminDeviceTaskDetail !== null, garminDirectSection)}
          {RIF(movesenseStreamTask && movesenseStreamTask.length, movesenseSection)}
          {/* {RIF(false, {actigraphyAnalysisSection})} */}
        </div>
      </div>
    </div>
  )
}

interface VisibleGraphDataListItemProps {
  addGraphType: (type: string) => void
  visualizerSidebarSetting: Record<string, any>
  visibleGraphDataType: string
  isLast?: boolean
}

const VisibleGraphDataListItem = (props: VisibleGraphDataListItemProps) => {
  const {color, fontSize} = selectTheme()
  const {addGraphType, visualizerSidebarSetting, visibleGraphDataType, isLast} = props
  const added = !!_.find(visualizerSidebarSetting, ['type', visibleGraphDataType])

  const buttonAddOnClick = () => {
    if (addGraphType) addGraphType(visibleGraphDataType)
  }

  return (
    <div
      key={visibleGraphDataType}
      css={{
        width: '100%',
        minHeight: '48px',
        padding: '8px 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: isLast ? 'none' : `1px solid ${color.grey_160}`,
        background: color.white,
        ':hover': {
          background: color.hover,
        },
      }}
    >
      <p
        css={{
          fontSize: fontSize.h7,
        }}
      >
        {DataTypeTranslator[visibleGraphDataType]}
      </p>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {RIF(
          added,
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '12px',
            }}
          >
            <img src={CheckGreyIcon} width={16} />
            <p
              css={{
                fontSize: fontSize.h7,
                color: color.grey_400,
                marginLeft: '4px',
              }}
            >
              Added
            </p>
          </div>,
        )}
        <ButtonAdd onClick={buttonAddOnClick} />
      </div>
    </div>
  )
}

interface LockedVisibleGraphDataListItemProps {
  visibleGraphDataTitle: string
  learnMoreHref: string
}

const LockedVisibleGraphDataListItem = (props: LockedVisibleGraphDataListItemProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {visibleGraphDataTitle, learnMoreHref} = props

  return (
    <div
      css={{
        width: '100%',
        minHeight: '48px',
        padding: '12px',
        background: color.lightYellow,
        borderBottom: `1px solid ${color.grey_160}`,
      }}
    >
      <div
        css={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '8px',
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img src={LockIcon} width={14} />
          <p
            css={{
              marginLeft: '4px',
              fontSize: fontSize.h7,
            }}
          >
            {visibleGraphDataTitle}
          </p>
        </div>
        <Button
          css={{fontSize: fontSize.h8}}
          {...{
            children: 'Unlock',
            btnPadding: 'small',
            btnColor: 'upgrade',
          }}
        />
      </div>
      <p
        css={{
          fontSize: fontSize.h7,
          color: color.grey_400,
        }}
      >
        Lorem ipsum d facilisis vitae etiam moleLo rem ipsum d facilisis vitae...&nbsp;
        <a href={learnMoreHref} css={{color: color.primary, fontSize: fontSize.h7, fontWeight: fontWeight.thick}}>
          Learn More
        </a>
      </p>
    </div>
  )
}

interface VisibleGraphDataDraggableProps {
  type: string
  index: number
  id: string
  removeGraphType: (index: number) => void
}

const VisibleGraphDataDraggable = (props: VisibleGraphDataDraggableProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {index, type, removeGraphType, id} = props

  const [hovered, setHovered] = useState(false)
  // const visibleGraphDataTitle = type.replace(/([A-Z])/g, ' $1')

  const handleMouseEnter = () => {
    setHovered(true)
  }

  const handleMouseLeave = () => {
    setHovered(false)
  }

  return (
    <Draggable key={id} draggableId={id} index={index}>
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            css={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
              background: color.white,
            }}
          >
            <DragHandle
              {...provided.dragHandleProps}
              isDragging={snapshot.isDragging || hovered}
              isShowing
              css={{
                height: '100%',
                width: '8px',
              }}
            />
            <div
              css={{
                width: 'calc(100% - 14px)',
                padding: '12px 10px',
                border: `1px solid ${hovered ? color.black : color.grey_160}`,
                borderRadius: '5px',
                marginLeft: '14px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                boxShadow: hovered ? '0px 4px 12px 0px #D4D4D440' : 'none',
              }}
            >
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <p>{DataTypeTranslator[type]}</p>
                {RIF(
                  type.includes('Garmin'),
                  <p
                    css={{
                      fontSize: fontSize.h7,
                      marginLeft: '8px',
                      color: color.grey_160,
                    }}
                  >
                    — From Garmin
                  </p>,
                )}
              </div>
              <button
                onClick={() => {
                  removeGraphType(index)
                }}
                css={{
                  border: 'none',
                  fontSize: fontSize.h7,
                  fontWeight: fontWeight.thick,
                  color: color.grey_160,
                  background: 'transparent',
                  padding: '4px 8px',
                  cursor: 'pointer',
                }}
              >
                Remove
              </button>
            </div>
          </div>
        )
      }}
    </Draggable>
  )
}
