import {DataFrame} from 'data-forge'
import {assertPartialSchema, createAction, useSelector, v} from '../../../lib'
import {AdherenceNormalTask, ParticipantDigestDay, TimeSeriesDataSource} from '../../../model'
import {BasicChunkMetaData, LocalDateDeviceLogData} from '../../../shared/mongo/localdate_device_logdata'
import {LocalDateDexcomData} from '../../../shared/mongo/localdate_dexcom_data'
import {TimeSeriesDataDbContent} from '../../db/db_data_setter'
import {IGarminDevice, VisualizerGraphDataType} from '../../../shared/db'
import {GarminDeviceLogDataType} from '../../../shared/mongo'

export enum ParticipantTaskTimelineDataActionType {
  PARTICIPANT_TASK_TIMELINE_DATA_SET = 'PARTICIPANT_TASK_TIMELINE_DATA_SET',
  PARTICIPANT_GARMIN_TASK_TIMELINE_DATA_SET = 'PARTICIPANT_GARMIN_TASK_TIMELINE_DATA_SET',
  PARTICIPANT_GARMIN_CONNECT_TIMELINE_DATA_SET = 'PARTICIPANT_GARMIN_CONNECT_TIMELINE_DATA_SET',
  PARTICIPANT_DEXCOM_TIMELINE_DATA_SET = 'PARTICIPANT_DEXCOM_TIMELINE_DATA_SET',
}
export const doPARTICIPANT_TASK_TIMELINE_DATA_SET = createAction(
  ParticipantTaskTimelineDataActionType.PARTICIPANT_TASK_TIMELINE_DATA_SET,
)

export const doPARTICIPANT_GARMIN_TASK_TIMELINE_DATA_SET = createAction(
  ParticipantTaskTimelineDataActionType.PARTICIPANT_GARMIN_TASK_TIMELINE_DATA_SET,
)

export const doPARTICIPANT_GARMIN_CONNECT_TIMELINE_DATA_SET = createAction(
  ParticipantTaskTimelineDataActionType.PARTICIPANT_GARMIN_CONNECT_TIMELINE_DATA_SET,
)

export const doPARTICIPANT_DEXCOM_TIMELINE_DATA_SET = createAction(
  ParticipantTaskTimelineDataActionType.PARTICIPANT_DEXCOM_TIMELINE_DATA_SET,
)

export interface ParticipantTaskTimelineObject {
  [participantId: string]: LocalDateTaskTimelineObject
}

export interface LocalDateTaskTimelineObject {
  [yymmdd: string]: TaskTimelineData
}

export interface TaskTimelineData {
  commonTask?: Record<string, TaskTimelineElement[]>
  garminTask?: Record<string, number[]>
  garminConnect?: Record<string, number[]>
  dexcomTask?: number[]
}

export type TimelineElementTaskType =
  | 'todo'
  | 'questionnaire'
  | 'timer'
  | 'stopwatch_movesense_stream'
  | 'stopwatch_movesense_log_data'
  | 'stopwatch_garmin_stream'
  | 'dexcom_logdata'
  | 'garmin_logdata'
  | 'garmin_connect'

export interface TaskTimelineElement {
  taskType: TimelineElementTaskType
  localStartTimestamp: number
  localEndTimestamp?: number
  completionId?: string
  timezone?: string
  timeOffset?: number
}

interface RootState {
  participantTaskTimelineData: ParticipantTaskTimelineObject
}
/* selector */
export const selectParticipantTaskTimelineData = () => {
  return useSelector((state: RootState) => state.participantTaskTimelineData)
}

export const participantTaskTimelineDataActionCreators = {
  doPARTICIPANT_TASK_DATA_SET: doPARTICIPANT_TASK_TIMELINE_DATA_SET,
}

export const participantTaskTimelineDefaultState: ParticipantTaskTimelineObject = {}

type Action =
  | {
      type: ParticipantTaskTimelineDataActionType.PARTICIPANT_TASK_TIMELINE_DATA_SET
      payload: {
        projectId: string
        participantDigestDay: ParticipantDigestDay
      }
    }
  | {
      type: ParticipantTaskTimelineDataActionType.PARTICIPANT_GARMIN_TASK_TIMELINE_DATA_SET
      payload: {
        participantId: string
        yymmddIndex: number
        garminDeviceTaskConfig: IGarminDevice
        dataTypeList: GarminDeviceLogDataType[]
        data: LocalDateDeviceLogData[]
      }
    }
  | {
      type: ParticipantTaskTimelineDataActionType.PARTICIPANT_GARMIN_CONNECT_TIMELINE_DATA_SET
      payload: {
        dbContentList: TimeSeriesDataDbContent[]
      }
    }
  | {
      type: ParticipantTaskTimelineDataActionType.PARTICIPANT_DEXCOM_TIMELINE_DATA_SET
      payload: {
        participantId: string
        yymmddIndexes: number[]
        data?: LocalDateDexcomData[]
      }
    }

const schemaParticipantDigestDayTask = {
  taskId: v.string().uuid().required(),
  taskType: v.string().required(),
  timestampList: v.array().items(
    v.object({
      unixTimestampStart: v.number().optional(),
      unixTimestampComplete: v.number().required(),
    }),
  ),
}

export const participantTaskTimelineDataReducer = (
  state: ParticipantTaskTimelineObject = participantTaskTimelineDefaultState,
  {type, payload}: Action,
) => {
  switch (type) {
    case ParticipantTaskTimelineDataActionType.PARTICIPANT_TASK_TIMELINE_DATA_SET: {
      assertPartialSchema({
        payload,
        schema: v.object({
          projectId: v.string().exist(),
          participantDigestDay: v.object({
            day: v.string().exist(),
            dayDate: v.string().exist(),
            dayUnixTimestamp: v.number(),
            participantId: v.string().uuid().exist(),
            garminConnectSynced: v.boolean(),
            garminConnect: v.object(),
            task: v.object(),
            dexcomDataCount: v.number(),
          }),
        }),
      })
      const {participantId, day, task} = payload.participantDigestDay
      const adherenceNormalTask = task as Record<string, AdherenceNormalTask>
      return updateParticipantTaskTimelineDataInState(state, participantId, day, adherenceNormalTask)
    }
    case ParticipantTaskTimelineDataActionType.PARTICIPANT_GARMIN_TASK_TIMELINE_DATA_SET: {
      assertPartialSchema({
        payload,
        schema: v.object({
          participantId: v.string().required(),
          yymmddIndex: v.number().required(),
          garminDeviceTaskConfig: v.object().required(),
          dataTypeList: v.array().items(v.string()),
          data: v.array().items(
            v.object({
              participantId: v.string().uuid().required(),
              dataType: v.string().required(),
              yymmddIndex: v.number().required(),
              data: v.object({
                rawDataMap: v.object().exist(),
                metaDataMap: v.object().exist(),
              }),
            }),
          ),
        }),
      })
      const {participantId, yymmddIndex, garminDeviceTaskConfig, dataTypeList, data} = payload
      return updateParticipantTaskTimelineGarminDataInState(
        state,
        participantId,
        yymmddIndex,
        dataTypeList,
        data,
        garminDeviceTaskConfig,
      )
    }
    case ParticipantTaskTimelineDataActionType.PARTICIPANT_GARMIN_CONNECT_TIMELINE_DATA_SET: {
      assertPartialSchema({
        payload,
        schema: v.object({
          dbContentList: v.array().items(
            v.object({
              participantId: v.string().required(),
              yymmddIndex: v.number().required(),
              timeSeriesForChartDbContentList: v.array().items(
                v.object({
                  dataType: v.string().required(),
                  data: v.array().exist(),
                }),
              ),
            }),
          ),
        }),
      })
      const {dbContentList} = payload
      return updateParticipantTaskTimelineGarminConnectInState(state, dbContentList)
    }
    case ParticipantTaskTimelineDataActionType.PARTICIPANT_DEXCOM_TIMELINE_DATA_SET: {
      assertPartialSchema({
        payload,
        schema: v.object({
          participantId: v.string().uuid().required(),
          yymmddIndexes: v.array().items(v.number()).required(),
          data: v.array().items(
            v.object({
              participantId: v.string().uuid().required(),
              yymmddIndex: v.number().required(),
              egvs: v.array().items(
                v.object({
                  displayTime: v.string().required(),
                  value: v.number(),
                }),
              ),
            }),
          ),
        }),
      })

      const {participantId, yymmddIndexes, data} = payload
      return updateParticipantTaskTimelineDexcomDataInState(state, participantId, yymmddIndexes, data)
    }
    default:
      return state
  }
}

function updateParticipantTaskTimelineDataInState(
  state: ParticipantTaskTimelineObject,
  participantId: string,
  isoDayString: string, // "2023-01-01"
  taskAdherenceData: Record<string, AdherenceNormalTask>,
) {
  const yymmdd = isoDayStringToYYMMDDIndex(isoDayString)

  if (!state[participantId]) {
    state[participantId] = {}
  }

  if (!state[participantId][yymmdd]) {
    state[participantId][yymmdd] = {
      commonTask: {},
    }
  }

  if (Object.keys(taskAdherenceData).length > 0) {
    for (const taskData of Object.values(taskAdherenceData)) {
      const timeLineElementList = convertTaskAdherenceToTimelineElementList(taskData)
      const taskTimelineData = state[participantId][yymmdd].commonTask
      if (taskTimelineData) {
        taskTimelineData[taskData.taskId] = timeLineElementList
      } else {
        state[participantId][yymmdd].commonTask = {
          [taskData.taskId]: timeLineElementList,
        }
      }
    }
  }
  return {...state}
}

function isoDayStringToYYMMDDIndex(isoDayString: string): string {
  const dateInfo = isoDayString.split('-')
  return `${dateInfo[0].slice(2)}${dateInfo[1]}${dateInfo[2]}`
}

function convertTaskAdherenceToTimelineElementList(taskData: AdherenceNormalTask): TaskTimelineElement[] {
  const timeLineElementList: TaskTimelineElement[] = []
  switch (taskData.taskType) {
    case 'todo':
    case 'questionnaire':
      for (const timestamp of taskData.timestampList) {
        timeLineElementList.push({
          taskType: taskData.taskType,
          timezone: timestamp.timezone,
          timeOffset: timestamp.timeOffset,
          localStartTimestamp: timestamp.unixTimestampComplete + timestamp.timeOffset,
        })
      }
      break
    case 'timer':
      for (const timestamp of taskData.timestampList) {
        if (timestamp.unixTimestampStart) {
          timeLineElementList.push({
            taskType: taskData.taskType,
            timezone: timestamp.timezone,
            timeOffset: timestamp.timeOffset,
            localStartTimestamp: timestamp.unixTimestampStart + timestamp.timeOffset,
            localEndTimestamp: timestamp.unixTimestampComplete + timestamp.timeOffset,
          })
        }
      }
      break
    case 'stopwatch_movesense_stream':
    case 'stopwatch_movesense_log_data':
    case 'stopwatch_garmin_stream':
      for (const timestamp of taskData.timestampList) {
        if (timestamp.unixTimestampStart && timestamp.completionId) {
          timeLineElementList.push({
            taskType: taskData.taskType,
            completionId: timestamp.completionId,
            timezone: timestamp.timezone,
            timeOffset: timestamp.timeOffset,
            localStartTimestamp: timestamp.unixTimestampStart + timestamp.timeOffset,
            localEndTimestamp: timestamp.unixTimestampComplete + timestamp.timeOffset,
          })
        }
      }
      break
  }
  return timeLineElementList
}

function updateParticipantTaskTimelineGarminDataInState(
  state: ParticipantTaskTimelineObject,
  participantId: string,
  yymmddIndex: number,
  dataTypeList: string[],
  garminDeviceDataList: LocalDateDeviceLogData[],
  garminDeviceTaskConfig: IGarminDevice,
) {
  if (garminDeviceDataList.length > 0) {
    for (const deviceData of garminDeviceDataList) {
      const {participantId, yymmddIndex, dataType} = deviceData
      const mergedTimestampList = convertGarminDeviceDataToTimestampList(deviceData, garminDeviceTaskConfig)
      const yymmdd = `${yymmddIndex}`

      if (!state[participantId]) {
        state[participantId] = {}
      }

      if (!state[participantId][yymmdd]) {
        state[participantId][yymmdd] = {
          garminTask: {},
        }
      }

      if (!state[participantId][yymmdd].garminTask) {
        state[participantId][yymmdd].garminTask = {}
      }

      const garminTaskData = state[participantId][yymmdd].garminTask
      if (garminTaskData) {
        garminTaskData[dataType] = mergedTimestampList
      }
    }
  } else {
    if (!state[participantId]) {
      state[participantId] = {}
    }

    if (!state[participantId][yymmddIndex]) {
      state[participantId][yymmddIndex] = {
        garminTask: {},
      }
    }

    if (!state[participantId][yymmddIndex].garminTask) {
      state[participantId][yymmddIndex].garminTask = {}
    }
    const garminTaskData = state[participantId][yymmddIndex].garminTask

    if (garminTaskData) {
      for (const dataType of dataTypeList) {
        garminTaskData[dataType] = []
      }
    }
  }

  return {...state}
}

function convertGarminDeviceDataToTimestampList(
  deviceData: LocalDateDeviceLogData,
  garminDeviceTaskConfig: IGarminDevice,
): number[] {
  const sortedChunkList = new DataFrame(Object.values(deviceData.rawDataMap)).orderBy((chunk) => chunk.queryUtcIndex)
  const firstChunk = sortedChunkList.at(0)
  let result: number[] = []

  if (firstChunk) {
    const timeOffsetRef = firstChunk.timeOffset
    const sortedMetaDataList: BasicChunkMetaData[] = new DataFrame(Object.values(deviceData.metaDataMap))
      .orderBy((chunk) => chunk.queryUtcIndex)
      .toArray()

    let timeDiffThreshold = 10000 // default 10 second
    // make sample rate * 2 as time diff threshold
    switch (deviceData.dataType) {
      case GarminDeviceLogDataType.GarminBBI:
        timeDiffThreshold = 10000
        break
      case GarminDeviceLogDataType.GarminHeartRate:
        timeDiffThreshold = garminDeviceTaskConfig.heartRateSampleRate * 20000
        break
      case GarminDeviceLogDataType.GarminPulseOx:
        timeDiffThreshold = garminDeviceTaskConfig.pulseOxSampleRate * 20000
        break
      case GarminDeviceLogDataType.GarminRespiration:
        timeDiffThreshold = garminDeviceTaskConfig.respirationSampleRate * 20000
        break
      case GarminDeviceLogDataType.GarminStress:
        timeDiffThreshold = garminDeviceTaskConfig.stressSampleRate * 20000
        break
      case GarminDeviceLogDataType.GarminActigraphy:
        timeDiffThreshold = garminDeviceTaskConfig.actigraphySampleRate * 20000
        break
      case GarminDeviceLogDataType.GarminStep:
        timeDiffThreshold = 720000
        break
      case GarminDeviceLogDataType.GarminZeroCrossing:
        timeDiffThreshold = garminDeviceTaskConfig.zeroCrossingSampleRate * 20000
        break
      default:
        break
    }

    const timestampList = []
    for (const metaData of Object.values(sortedMetaDataList)) {
      timestampList.push(metaData.firstDataTime)
      timestampList.push(metaData.lastDataTime)
    }
    const mergedTimestampList =
      timestampList.length > 2
        ? mergeDataChunkTimestampBaseOnDiffThreshold(timestampList, timeDiffThreshold)
        : timestampList
    const timeOffsetProcessedTimestampList = mergedTimestampList.map((item) => item + timeOffsetRef)
    result = timeOffsetProcessedTimestampList
  }

  return result
}

function mergeDataChunkTimestampBaseOnDiffThreshold(timestampList: number[], diffThreshold: number): number[] {
  const removeItemIndex = []
  for (let i = timestampList.length - 2; i > 0; i = i - 2) {
    if (timestampList[i] - timestampList[i - 1] < diffThreshold) {
      removeItemIndex.push(i)
      removeItemIndex.push(i - 1)
    }
  }
  for (let i = 0; i < removeItemIndex.length; i++) {
    timestampList.splice(removeItemIndex[i], 1)
  }

  return timestampList
}

function updateParticipantTaskTimelineGarminConnectInState(
  state: ParticipantTaskTimelineObject,
  dbContentList: TimeSeriesDataDbContent[],
) {
  for (const dbContent of dbContentList) {
    const {participantId, yymmddIndex, timeSeriesForChartDbContentList} = dbContent

    if (timeSeriesForChartDbContentList.length > 0) {
      for (const dbContent of timeSeriesForChartDbContentList) {
        const {dataType, data} = dbContent
        const mergedTimestampList = convertGarminConnectDataToTimestampList(dataType, data)
        const yymmdd = `${yymmddIndex}`

        if (!state[participantId]) {
          state[participantId] = {}
        }

        if (!state[participantId][yymmdd]) {
          state[participantId][yymmdd] = {
            garminConnect: {},
          }
        }

        if (!state[participantId][yymmdd].garminConnect) {
          state[participantId][yymmdd].garminConnect = {}
        }

        const garminTaskData = state[participantId][yymmdd].garminConnect
        if (garminTaskData) {
          garminTaskData[dataType] = mergedTimestampList
        }
      }
    }
  }

  return {...state}
}

function convertGarminConnectDataToTimestampList(
  dataType: VisualizerGraphDataType,
  timeSeriesData: TimeSeriesDataSource[],
): number[] {
  if (timeSeriesData.length > 0) {
    let dataSampleRate = 10000 // default 10 second
    // make sample rate * 2 as time diff threshold
    switch (dataType) {
      case VisualizerGraphDataType.GarminConnectStress:
      case VisualizerGraphDataType.GarminConnectBodyBattery:
        dataSampleRate = 180000 // 3 minutes
        break
      case VisualizerGraphDataType.GarminConnectHeartRate:
        dataSampleRate = 15000 // 15 seconds
        break
      case VisualizerGraphDataType.GarminConnectSteps:
        dataSampleRate = 900000 // 15 minutes
        break
      default:
        break
    }

    const timeStampList = timeSeriesData.map((data) => data.t)
    // merge timeChunk
    const mergedTimestampList =
      timeSeriesData.length > 2 ? mergeTimestampBaseOnDiffThreshold(timeStampList, dataSampleRate * 1.5) : timeStampList

    // because the time range are converted from data time point, it lacks of duration on range end
    // so add last duration to the time pair end
    return mergedTimestampList.map((item, index) => {
      return index % 2 === 0 ? item : item + dataSampleRate
    })
  }

  return []
}

function updateParticipantTaskTimelineDexcomDataInState(
  state: ParticipantTaskTimelineObject,
  participantId: string,
  yymmddIndexes: number[],
  dexcomDataList?: LocalDateDexcomData[],
) {
  for (const yymmddIndex of yymmddIndexes) {
    const dexcomData = dexcomDataList
      ? dexcomDataList.filter((data) => {
          return data.yymmddIndex === yymmddIndex
        })?.[0]
      : undefined

    const mergedTimestampList = dexcomData ? convertDexcomDataToTimestampList(dexcomData) : []
    const yymmdd = `${yymmddIndex}`

    if (!state[participantId]) {
      state[participantId] = {}
    }

    if (!state[participantId][yymmdd]) {
      state[participantId][yymmdd] = {
        dexcomTask: mergedTimestampList,
      }
    } else {
      state[participantId][yymmdd].dexcomTask = mergedTimestampList
    }
  }

  return state
}

function mergeTimestampBaseOnDiffThreshold(timestampList: number[], diffThreshold: number): number[] {
  const removeItemIndex = []
  for (let i = timestampList.length - 2; i > 0; i--) {
    if (
      timestampList[i] - timestampList[i - 1] < diffThreshold &&
      timestampList[i + 1] - timestampList[i] < diffThreshold
    ) {
      removeItemIndex.push(i)
    }
  }
  for (let i = 0; i < removeItemIndex.length; i++) {
    timestampList.splice(removeItemIndex[i], 1)
  }
  return timestampList
}

function convertDexcomDataToTimestampList(dexcom: LocalDateDexcomData): number[] {
  function extractTimeOffsetInSeconds(isoString: string): number {
    const pattern = /([+-]\d{2}):(\d{2})$/
    const match = pattern.exec(isoString)

    if (match && match.length === 3) {
      const hours = parseInt(match[1], 10)
      const minutes = parseInt(match[2], 10)
      return (hours * 60 + minutes) * 60000
    } else {
      return new Date().getTimezoneOffset() * 60000
    }
  }

  const sortedTimestampList = new DataFrame(dexcom.egvs)
    .map((egv) => {
      const dataDate = new Date(egv.displayTime)
      const timeOffset = extractTimeOffsetInSeconds(egv.displayTime)
      return dataDate.getTime() + timeOffset
    })
    .orderBy((data) => data)
    .toArray()

  // merge timeDiffer in 6 minute timeChunk
  const timeDiffThreshold = 360000
  const mergedTimestampList =
    sortedTimestampList.length > 2
      ? mergeTimestampBaseOnDiffThreshold(sortedTimestampList, timeDiffThreshold)
      : sortedTimestampList

  // because the time range are converted from data time point, it lacks of duration on range end
  // so add 5 minutes to the time pair end
  const processedResult = mergedTimestampList.map((item, index) => {
    return index % 2 === 0 ? item : item + 300000
  })

  return processedResult
}
