import {selectTheme} from '../../store'

export const VariableTag = (props: {identifier?: string}) => {
  const {identifier} = props
  const {color, fontSize, fontWeight} = selectTheme()

  return (
    <p
      css={{
        minWidth: '12px',
        minHeight: '12px',
        lineHeight: '12px',
        borderRadius: '50%',
        fontSize: fontSize.h8,
        fontWeight: fontWeight.thick,
        color: color.white,
        backgroundColor: color.surface.dark,
        textAlign: 'center',
        marginRight: '4px',
      }}
    >
      {identifier ? identifier.charAt(identifier.length - 1) : 'A'}
    </p>
  )
}
