import {RIF, RequestResult, _, isInBetaType} from '../../lib'
import {
  createDispatchActions,
  selectProjectData,
  selectTheme,
  useSelectSettings,
  selectBatchData,
  selectAnalyticVariable,
  selectProjectSettings,
} from '../../store'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useState, useEffect, useMemo} from 'react'
import {ArrowLeftIcon} from '../../asset/image'
import {DataDownloadPage, AnalysisSideBar, AnalysisCorrelationMatrixBlock, AnalysisTrendGraphBlock} from '..'
import {IProject, IBatch, BetaType} from '../../shared/db'
import {ParticipantItem} from '../../model/participant'
import {DateObject} from 'react-multi-date-picker'
import {AttemptRequestParticipantStatus} from './request_project_data_hook'
import {CorrelationResponse, TrendResponse, TrendResultOLSData, Variable} from '../../shared/analysis'
import {DataSetSelectionItem} from '../../model/analysis'
import {
  AnalysisDataResolverArgs,
  useResolveCorrelationApiCall,
  useResolveTrendApiCall,
} from '../../lib/chart_data/data_loader/analysis_data_resolver'

export const AnalysisPage = () => {
  const {color, fontWeight, fontSize} = selectTheme()
  const {
    doREQUEST_PROJECT_FETCH,
    doREQUEST_IDENTITY_PROJECT_SETTINGS_LIST_FETCH,
    doREQUEST_ANALYTIC_VARIABLE_LIST_FETCH,
  }: // eslint-disable-next-line @typescript-eslint/ban-types
  Record<string, Function> = createDispatchActions()
  const navigate = useNavigate()
  AttemptRequestParticipantStatus({})
  const settings = useSelectSettings()
  const projectId = useParams().projectId ?? ''
  const analysisType = useParams().analysisType ?? 'correlation'
  if (analysisType !== 'correlation' && analysisType !== 'trend') {
    navigate(`/data_analysis/correlation/${projectId}`)
  }
  const projectData = selectProjectData() as Record<string, IProject>
  const project = projectId ? projectData[projectId] : undefined
  const projectSettings = projectId ? (selectProjectSettings()[projectId] as any) : undefined
  const correlationVariableList = selectAnalyticVariable(projectId)
  const batchId = _.head(project?.batchList)?.id
  const batchData = selectBatchData() as Record<string, IBatch>
  const batch = batchId ? batchData[batchId] : undefined
  const [duration, setDuration] = useState<[DateObject, DateObject]>([
    new DateObject().subtract(7, 'days'),
    new DateObject(),
  ])
  const [currentParticipantId, setCurrentParticipantId] = useState<string>('')
  const [resolution, setResolution] = useState<any>()
  const [participantList, setParticipantList] = useState<ParticipantItem[]>([])
  // the actual variable list that will be passed into the correlation graph
  const [displayingVariableList, setDisplayingVariableList] = useState<Variable[]>([])
  const [displayingDataSetSelectionList, setDisplayingDataSetSelectionList] = useState<DataSetSelectionItem[]>([])

  const [renderDataDownloadPage, setRenderDataDownloadPage] = useState(false)
  const [requestResultOfAnalyticGraphCorrelationFetch, setRequestResultOfAnalyticGraphCorrelationFetch] =
    useState<RequestResult | null>(null)
  const [requestResultOfAnalyticGraphTrendFetch, setRequestResultOfAnalyticGraphTrendFetch] =
    useState<RequestResult | null>(null)
  const [correlationResponse, setCorrelationResponse] = useState<CorrelationResponse>()
  const [trendResponse, setTrendResponse] = useState<TrendResponse>()
  const [trendOlsData, setTrendOlsData] = useState<TrendResultOLSData[]>([])
  // disable analysis page in production
  useEffect(() => {
    if (process.env.NODE_ENV === 'NODE_ENV') navigate('/project')
  }, [])

  useMemo(() => {
    if (!batch?.participantList) return
    const result = batch.participantList.map(({id, insignia, tagList}): ParticipantItem => {
      return {
        id,
        insignia: insignia ?? '',
        tagList: tagList ?? [],
      }
    })
    if (!_.isEqual(participantList, result)) {
      setParticipantList(result)
    }
  }, [batch])

  useEffect(() => {
    if (requestResultOfAnalyticGraphCorrelationFetch?.success) {
      setCorrelationResponse(requestResultOfAnalyticGraphCorrelationFetch.result.payload)
    }
  }, [requestResultOfAnalyticGraphCorrelationFetch])

  useEffect(() => {
    if (requestResultOfAnalyticGraphTrendFetch?.success) {
      setTrendOlsData(requestResultOfAnalyticGraphTrendFetch.result.payload.result.data as TrendResultOLSData[])
      setTrendResponse(requestResultOfAnalyticGraphTrendFetch.result.payload)
    }
  }, [requestResultOfAnalyticGraphTrendFetch])

  const onParticipantSelect = (participant: ParticipantItem) => {
    setCurrentParticipantId(participant.id)
  }

  AttemptRequestParticipantStatus({
    requestAdherence: {durationBeforeToday: 6},
  })

  const resolveCorrelationApiCall = useResolveCorrelationApiCall()
  const resolveTrendApiCall = useResolveTrendApiCall()

  const onUpdateAnalysisArgs = (args: AnalysisDataResolverArgs) => {
    if (analysisType === 'correlation') {
      resolveCorrelationApiCall({
        ...args,
        setRequestResult: setRequestResultOfAnalyticGraphCorrelationFetch,
      })
    } else if (analysisType === 'trend') {
      resolveTrendApiCall({
        ...args,
        setRequestResult: setRequestResultOfAnalyticGraphTrendFetch,
      })
    }
  }

  useEffect(() => {
    if (!projectId) return
    if (!project) {
      doREQUEST_PROJECT_FETCH({
        payload: {
          projectId,
        },
      })
    }
    if (!projectSettings) {
      doREQUEST_IDENTITY_PROJECT_SETTINGS_LIST_FETCH({
        payload: {},
      })
    }
    if (!correlationVariableList.length) {
      doREQUEST_ANALYTIC_VARIABLE_LIST_FETCH({
        payload: {
          projectId,
        },
      })
    }
  }, [projectId])

  useEffect(() => {
    if (!participantList.length) return
    if (currentParticipantId.length) return
    setCurrentParticipantId(_.head(participantList)?.id || '')
  }, [participantList])

  useMemo(() => {
    if (!correlationVariableList || correlationVariableList.length === 0) return
    // randomly select 2 variables to display
    const initDataTypeList = _.slice(correlationVariableList, 0, 2)
    setDisplayingVariableList(initDataTypeList)
  }, [correlationVariableList])

  return RIF(
    isInBetaType({betaType: BetaType.ClosedBeta, settings}),
    <div
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 104,
        width: '100vw',
        height: '100vh',
        backgroundColor: color.background,
      }}
    >
      {RIF(renderDataDownloadPage, <DataDownloadPage {...{closeAction: () => setRenderDataDownloadPage(false)}} />)}
      {/* title bar */}
      <div
        css={{
          width: '100vw',
          height: '56px',
          boxShadow: '0 2px 10px 0 rgba(0,0,0,0.04)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: color.white,
          padding: '8px 32px 8px 16px',
          zIndex: 99,
          position: 'relative',
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <div
            css={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              paddingRight: '8px',
              borderRight: `1px solid ${color.grey_100}`,
            }}
          >
            <Link
              css={{
                width: '28px',
                height: '28px',
                borderRadius: '5px',
                backgroundColor: color.white,
                backgroundImage: `url(${ArrowLeftIcon})`,
                backgroundPosition: 'center',
                backgroundSize: '20px',
                backgroundRepeat: 'no-repeat',
                ':hover': {
                  backgroundColor: color.hover,
                },
              }}
              to={`/adherence/${projectId}`}
            />
          </div>
          <p
            css={{
              fontWeight: fontWeight.thick,
              marginLeft: '20px',
            }}
          >
            Data Analysis
          </p>
        </div>
        <div
          css={{
            padding: '8px',
            display: 'flex',
            alignItems: 'center',
            border: `1px solid ${color.border._80}`,
            borderRadius: '999px',
          }}
        >
          <Link to={`/data_analysis/correlation/${projectId}`}>
            <button
              css={{
                fontSize: fontSize.h7,
                fontWeight: analysisType === 'correlation' ? fontWeight.thick : fontWeight.medium,
                padding: '4px 16px',
                border: `1px solid ${analysisType === 'correlation' ? color.surface.blue.dark : color.white}`,
                borderRadius: '999px',
                backgroundColor: analysisType === 'correlation' ? color.surface.blue.light : color.white,
                display: 'flex',
                alignItems: 'center',
                color: analysisType === 'correlation' ? color.textIcon.link : color.textIcon.secondary,
                marginRight: '8px',
                cursor: 'pointer',
              }}
            >
              Correlation and Distribution Analysis
            </button>
          </Link>
          <Link to={`/data_analysis/trend/${projectId}`}>
            <button
              css={{
                fontSize: fontSize.h7,
                fontWeight: analysisType === 'trend' ? fontWeight.thick : fontWeight.medium,
                padding: '4px 16px',
                border: `1px solid ${analysisType === 'trend' ? color.surface.blue.dark : color.white}`,
                borderRadius: '999px',
                backgroundColor: analysisType === 'trend' ? color.surface.blue.light : color.white,
                display: 'flex',
                alignItems: 'center',
                color: analysisType === 'trend' ? color.textIcon.link : color.textIcon.secondary,
                marginRight: '8px',
                cursor: 'pointer',
              }}
            >
              Trend Analysis
            </button>
          </Link>
        </div>
        <div></div>
      </div>
      {/* page content */}
      <div css={{display: 'flex'}}>
        <AnalysisSideBar
          {...{
            participantList,
            onParticipantSelect,
            currentParticipantId,
            duration,
            setDuration,
            resolution,
            setResolution,
            displayingVariableList,
            setDisplayingVariableList,
            displayingDataSetSelectionList,
            setDisplayingDataSetSelectionList,
            onUpdateAnalysisArgs,
          }}
        />
        {/* right part of page content */}
        {RIF(
          analysisType === 'correlation',
          <div
            css={{
              padding: '32px 24px',
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <p
              css={{
                fontWeight: fontWeight.thick,
                fontSize: fontSize.h3,
                marginBottom: '16px',
              }}
            >
              Correlation and Distribution Analysis
            </p>
            <AnalysisCorrelationMatrixBlock
              {...{
                participantInsignia:
                  _.find(participantList, ['id', currentParticipantId])?.insignia ?? 'Unknown Participant',
                duration,
                displayingVariableList,
                correlationResponse,
              }}
            />
          </div>,
        )}
        {RIF(
          analysisType === 'trend',
          <div
            css={{
              padding: '32px 24px',
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <p
              css={{
                fontWeight: fontWeight.thick,
                fontSize: fontSize.h3,
                marginBottom: '16px',
              }}
            >
              Trend Analysis
            </p>
            <AnalysisTrendGraphBlock
              {...{
                // participantInsignia:
                //   _.find(participantList, ['id', currentParticipantId])?.insignia ?? 'Unknown Participant',
                participantList,
                duration,
                displayingVariableList,
                trendResponse,
                trendOlsData,
              }}
            />
          </div>,
        )}
      </div>
    </div>,
  )
}
