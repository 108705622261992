import {createDispatchActions} from '../../../store'

import {Variable} from '../../../shared/analysis/variable'
import {
  AnalysisSidebarSubject,
  AnalysisSidebarSubjectGroup,
  AnalysisSidebarSubjectParticipant,
  AnalysisSidebarSubjectType,
} from '../../../model/analysis'
import {DateObject} from 'react-multi-date-picker'
import {FetchDuration} from '../../../model'
import {Dispatch, SetStateAction, useCallback} from 'react'
import {durationToYYMMDDInterval} from '..'
import {RequestResult} from '../../create_action'

export interface AnalysisDataResolverArgs {
  subjects: AnalysisSidebarSubject[]
  durations: [DateObject, DateObject][]
  variables: Variable[]
}

export interface ResolveCorrelationApiCallProps extends AnalysisDataResolverArgs {
  setRequestResult: Dispatch<SetStateAction<RequestResult | null>>
}

export const useResolveCorrelationApiCall = () => {
  const {
    doREQUEST_ANALYTIC_GRAPH_CORRELATION_GROUP_FETCH,
    doREQUEST_ANALYTIC_GRAPH_CORRELATION_GROUP_MULTIPLE_DURATION_FETCH,
    doREQUEST_ANALYTIC_GRAPH_CORRELATION_INDIVIDUAL_FETCH,
    doREQUEST_ANALYTIC_GRAPH_CORRELATION_MULTIPLE_INDIVIDUAL_FETCH,
    doREQUEST_ANALYTIC_GRAPH_CORRELATION_INDIVIDUAL_MULTIPLE_DURATION_FETCH,
    doREQUEST_ANALYTIC_GRAPH_CORRELATION_MULTIPLE_INDIVIDUAL_MULTIPLE_DURATION_FETCH,
    doREQUEST_ANALYTIC_GRAPH_CORRELATION_MULTIPLE_GROUP_FETCH,
    doREQUEST_ANALYTIC_GRAPH_CORRELATION_MULTIPLE_GROUP_MULTIPLE_DURATION_FETCH,
  }: // eslint-disable-next-line @typescript-eslint/ban-types
  Record<string, Function> = createDispatchActions()

  return useCallback((props: ResolveCorrelationApiCallProps) => {
    const {subjects, durations, variables, setRequestResult} = props
    const correlationSubjectType = subjects[0].type
    const isMultipleSubject = subjects.length > 1
    const isMultipleDuration = durations.length > 1

    switch (correlationSubjectType) {
      case AnalysisSidebarSubjectType.Individual: {
        const individualSubjects = subjects as AnalysisSidebarSubjectParticipant[]
        if (isMultipleSubject && isMultipleDuration) {
          doREQUEST_ANALYTIC_GRAPH_CORRELATION_MULTIPLE_INDIVIDUAL_MULTIPLE_DURATION_FETCH({
            setRequestResult,
            payload: {
              participantIds: individualSubjects.map(({participantId}) => participantId),
              yymmddDurations: durationsToFetchDurations(durations),
              variables,
            },
          })
        } else if (!isMultipleSubject && isMultipleDuration) {
          doREQUEST_ANALYTIC_GRAPH_CORRELATION_INDIVIDUAL_MULTIPLE_DURATION_FETCH({
            setRequestResult,
            payload: {
              participantId: individualSubjects[0].participantId,
              yymmddDurations: durationsToFetchDurations(durations),
              variables,
            },
          })
        } else if (isMultipleSubject && !isMultipleDuration) {
          doREQUEST_ANALYTIC_GRAPH_CORRELATION_MULTIPLE_INDIVIDUAL_FETCH({
            setRequestResult,
            payload: {
              participants: individualSubjects.map(({participantId}) => ({
                participantId,
                ...durationToFetchDuration(durations[0]),
              })),
              variables,
            },
          })
        } else if (!isMultipleSubject && !isMultipleDuration) {
          doREQUEST_ANALYTIC_GRAPH_CORRELATION_INDIVIDUAL_FETCH({
            setRequestResult,
            payload: {
              participantId: individualSubjects[0].participantId,
              variables,
              ...durationToFetchDuration(durations[0]),
            },
          })
        }
        break
      }
      case AnalysisSidebarSubjectType.Group: {
        const subjectGroups = subjects as AnalysisSidebarSubjectGroup[]
        if (isMultipleSubject && isMultipleDuration) {
          doREQUEST_ANALYTIC_GRAPH_CORRELATION_MULTIPLE_GROUP_MULTIPLE_DURATION_FETCH({
            setRequestResult,
            payload: {
              groups: subjectGroupsToFetchGroups(subjectGroups),
              yymmddDurations: durationsToFetchDurations(durations),
              variables,
            },
          })
        } else if (!isMultipleSubject && isMultipleDuration) {
          doREQUEST_ANALYTIC_GRAPH_CORRELATION_GROUP_MULTIPLE_DURATION_FETCH({
            setRequestResult,
            payload: {
              participantIds: subjectGroups[0].participantIds,
              yymmddDurations: durationsToFetchDurations(durations),
              variables,
            },
          })
        } else if (isMultipleSubject && !isMultipleDuration) {
          doREQUEST_ANALYTIC_GRAPH_CORRELATION_MULTIPLE_GROUP_FETCH({
            setRequestResult,
            payload: {
              groups: subjectGroupsToFetchGroupsWithDuration(subjectGroups, durations[0]),
              variables,
            },
          })
        } else if (!isMultipleSubject && !isMultipleDuration) {
          doREQUEST_ANALYTIC_GRAPH_CORRELATION_GROUP_FETCH({
            setRequestResult,
            payload: {
              participantIds: subjectGroups[0].participantIds,
              variables,
              ...durationToFetchDuration(durations[0]),
            },
          })
        }
      }
    }
  }, [])
}

export const useResolveTrendApiCall = () => {
  const {
    doREQUEST_ANALYTIC_GRAPH_TREND_GROUP_FETCH,
    doREQUEST_ANALYTIC_GRAPH_TREND_GROUP_MULTIPLE_DURATION_FETCH,
    doREQUEST_ANALYTIC_GRAPH_TREND_INDIVIDUAL_FETCH,
    doREQUEST_ANALYTIC_GRAPH_TREND_MULTIPLE_INDIVIDUAL_FETCH,
    doREQUEST_ANALYTIC_GRAPH_TREND_INDIVIDUAL_MULTIPLE_DURATION_FETCH,
    doREQUEST_ANALYTIC_GRAPH_TREND_MULTIPLE_GROUP_FETCH,
  }: // eslint-disable-next-line @typescript-eslint/ban-types
  Record<string, Function> = createDispatchActions()

  return useCallback((props: ResolveCorrelationApiCallProps) => {
    const {subjects, durations, variables, setRequestResult} = props
    const correlationSubjectType = subjects[0].type
    const isMultipleSubject = subjects.length > 1
    const isMultipleDuration = durations.length > 1

    switch (correlationSubjectType) {
      case AnalysisSidebarSubjectType.Individual: {
        const individualSubjects = subjects as AnalysisSidebarSubjectParticipant[]
        if (!isMultipleSubject && isMultipleDuration) {
          doREQUEST_ANALYTIC_GRAPH_TREND_INDIVIDUAL_MULTIPLE_DURATION_FETCH({
            setRequestResult,
            payload: {
              participantId: individualSubjects[0].participantId,
              yymmddDurations: durationsToFetchDurations(durations),
              variables,
            },
          })
        } else if (isMultipleSubject && !isMultipleDuration) {
          const {yymmddIndexStart, yymmddIndexEnd} = durationToFetchDuration(durations[0])
          doREQUEST_ANALYTIC_GRAPH_TREND_MULTIPLE_INDIVIDUAL_FETCH({
            setRequestResult,
            payload: {
              participantIds: individualSubjects.map(({participantId}) => participantId),
              yymmddIndexStart,
              yymmddIndexEnd,
              variables,
            },
          })
        } else if (!isMultipleSubject && !isMultipleDuration) {
          doREQUEST_ANALYTIC_GRAPH_TREND_INDIVIDUAL_FETCH({
            setRequestResult,
            payload: {
              participantId: individualSubjects[0].participantId,
              variables,
              ...durationToFetchDuration(durations[0]),
            },
          })
        }
        break
      }
      case AnalysisSidebarSubjectType.Group: {
        const subjectGroups = subjects as AnalysisSidebarSubjectGroup[]
        if (!isMultipleSubject && isMultipleDuration) {
          doREQUEST_ANALYTIC_GRAPH_TREND_GROUP_MULTIPLE_DURATION_FETCH({
            setRequestResult,
            payload: {
              participantIds: subjectGroups[0].participantIds,
              yymmddDurations: durationsToFetchDurations(durations),
              variables,
            },
          })
        } else if (isMultipleSubject && !isMultipleDuration) {
          const {yymmddIndexStart, yymmddIndexEnd} = durationToFetchDuration(durations[0])
          doREQUEST_ANALYTIC_GRAPH_TREND_MULTIPLE_GROUP_FETCH({
            setRequestResult,
            payload: {
              groups: subjectGroupsToFetchGroups(subjectGroups),
              yymmddIndexStart,
              yymmddIndexEnd,
              variables,
            },
          })
        } else if (!isMultipleSubject && !isMultipleDuration) {
          doREQUEST_ANALYTIC_GRAPH_TREND_GROUP_FETCH({
            setRequestResult,
            payload: {
              participantIds: subjectGroups[0].participantIds,
              variables,
              ...durationToFetchDuration(durations[0]),
            },
          })
        }
      }
    }
  }, [])
}

function durationsToFetchDurations(durations: [DateObject, DateObject][]): Record<string, FetchDuration> {
  return Object.fromEntries(
    durations.map((duration) => [
      `${duration[0].format('MMM D')} - ${duration[1].format('MMM D')}`,
      durationToFetchDuration(duration),
    ]),
  )
}

function durationToFetchDuration(duration: [DateObject, DateObject]): FetchDuration {
  const [yymmddIndexStart, yymmddIndexEnd] = durationToYYMMDDInterval(duration)
  return {yymmddIndexStart, yymmddIndexEnd}
}

function subjectGroupsToFetchGroups(groups: AnalysisSidebarSubjectGroup[]): Record<string, string[]> {
  return Object.fromEntries(groups.map((group) => [group.name, group.participantIds]))
}

const subjectGroupsToFetchGroupsWithDuration = (
  groups: AnalysisSidebarSubjectGroup[],
  duration: [DateObject, DateObject],
) => {
  return Object.fromEntries(
    groups.map((group) => [group.name, {group: group.participantIds, ...durationToFetchDuration(duration)}]),
  )
}
